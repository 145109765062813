<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';

import { inject } from 'vue';

import TextHeader from '@/Components/Partitials/TextHeader.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import Breadcrumb from '@/Components/Breadcrumb.vue';

const route = inject('route');

const props = defineProps({
    page: Object,
    faqs: Object,
});
</script>

<template>
    <PublicLayout
        :description="page?.seo_description"
        :title="page?.seo_title"
    >
        <Breadcrumb :pages="[{ name: $t('FAQ'), href: route('public.faq'), current: true }]" />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <TextHeader
                :title="$t('Frequently asked questions')"
                class="mx-auto mb-12 w-4/5"
            />

            <dl class="space-y-6">
                <Disclosure
                    v-for="faq in faqs"
                    v-if="faqs.length"
                    :key="faq.question"
                    v-slot="{ open }"
                    as="div"
                    class="pt-6"
                >
                    <dt>
                        <DisclosureButton
                            class="flex w-full items-start justify-between rounded-xl bg-lightGray-400 p-5 text-left text-gray-900"
                        >
                            <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                            <span class="ml-6 flex h-7 items-center">
                                <!--                                  <PlusSmallIcon v-if="!open" aria-hidden="true" class="h-6 w-6"/>-->
                                <!--                                  <MinusSmallIcon v-else aria-hidden="true" class="h-6 w-6"/>-->
                            </span>
                        </DisclosureButton>
                    </dt>
                    <DisclosurePanel
                        as="dd"
                        class="mt-2 pr-12"
                        static
                    >
                        <p class="text-base leading-7 text-gray-600">
                            {{ faq.answer }}
                        </p>
                    </DisclosurePanel>
                </Disclosure>

                <div
                    v-else
                    class="mt-20"
                >
                    <div class="text-4xl font-bold">
                        <span>{{ $t('No frequently asked questions found') }}</span>
                    </div>
                </div>
            </dl>
        </div>
    </PublicLayout>
</template>
