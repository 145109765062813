<script setup>
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref } from 'vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import ProductImage from '@/Components/Product/ProductImage.vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import { Navigation, Pagination } from 'swiper/modules';

const props = defineProps({
    media: Object,
});

const shownPhoto = ref(0);
const carouselIndex = ref(1);

const modalMedia = ref(null);

const mediaList = computed(() => {
    return props.media;
});
</script>

<template>
    <div class="relative">
        <div
            v-for="(item, index) in mediaList"
            v-show="shownPhoto === index"
            :key="index"
            class="relative w-full"
        >
            <ProductImage
                :media="item"
                @click="modalMedia = item"
            />
        </div>

        <div class="mt-3 flex w-full gap-2">
            <button
                v-if="mediaList.length === 4"
                :disabled="!(carouselIndex != 1)"
                @click="carouselIndex = carouselIndex - 1"
            >
                <ChevronLeftIcon
                    class="size-10"
                    :class="!(carouselIndex != 1) ? 'text-gray-400' : 'text-dark'"
                />
            </button>

            <div
                v-for="(item, index) in mediaList"
                v-show="
                    (carouselIndex === index || carouselIndex + 1 === index || carouselIndex - 1 === index) &&
                        mediaList.length > 1
                "
                :key="index"
                class="relative transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"
                @click="
                    (shownPhoto = index), index !== mediaList.length - 1 && index !== 0 ? (carouselIndex = index) : null
                "
            >
                <img
                    :alt="item?.alt_text ?? 'General shop image'"
                    :src="item.src"
                    :srcset="item?.src_set"
                    class="h-12 w-14 rounded object-cover min-[450px]:h-18 min-[450px]:w-22"
                >
            </div>
            <button
                v-if="mediaList.length === 4"
                :disabled="!(carouselIndex < mediaList.length - 2)"
                @click="carouselIndex = carouselIndex + 1"
            >
                <ChevronRightIcon
                    class="size-10"
                    :class="!(carouselIndex < mediaList.length - 2) ? 'text-gray-400' : 'text-dark'"
                />
            </button>
        </div>
    </div>

    <div
        v-if="modalMedia?.src"
        class="fixed left-0 top-0 z-50 flex size-full select-none items-center justify-center bg-gray-900 bg-opacity-75"
    >
        <button
            class="absolute right-0 top-0 z-50 mr-1 mt-1 inline-flex rounded-full text-white hover:text-gray-500"
            type="button"
            @click="modalMedia = null"
        >
            <span class="sr-only">{{ $t('Close') }}</span>
            <XMarkIcon
                aria-hidden="true"
                class="size-15"
            />
        </button>
        <swiper
            :initial-slide="shownPhoto"
            :modules="[Navigation, Pagination]"
            :style="{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
            }"
            navigation
            pagination
            @click="modalMedia = null"
        >
            <swiper-slide v-for="(item, index) in mediaList">
                <div class="flex size-full place-content-center items-center">
                    <img
                        :alt="item?.alt_text ?? 'General shop image'"
                        :src="item.src"
                        :srcset="item?.src_set"
                        class="size-full max-h-full max-w-full object-contain"
                    >
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<style scoped>
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    overflow: hidden;
}
</style>
