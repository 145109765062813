import "../css/app.css";
import "@/bootstrap";

import {createInertiaApp} from "@inertiajs/vue3";
import {createSSRApp, h} from "vue";
import {createGtm} from "@gtm-support/vue-gtm";
import {createPinia} from "pinia";
import dayjs from "@/plugins/dayjs";
import {ZiggyVue} from '@/plugins/ziggy';
import {Ziggy} from '@/ziggy';
import {createI18n} from "vue-i18n";
import localeMessages from "@/vue-i18n-locales.generated";
import {flareVue} from "@flareapp/vue";
import flare from '@/plugins/flare';
import formKitTheme from "@/formkitTheme";
import {createAutoAnimatePlugin} from "@formkit/addons";
import {nl} from "@formkit/i18n";
import {arrowDown, arrowUp, check, circle, close, down, fileDoc, spinner, star, trash} from "@formkit/icons";
import {generateClasses} from "@formkit/themes";
import {defaultConfig as FKdc, plugin as FKp} from "@formkit/vue";
import Notifications from "notiwind";

const pinia = createPinia();

if (import.meta.env.PROD) {
    flare.light();
}

const numberFormats = {
    nl: {
        currency: {
            style: "currency",
            currency: "EUR",
        },

        percent: {
            style: "percent",
            useGrouping: true,
        },
    },
};

createInertiaApp({
    progress: {
        delay: 250,
        color: "#ef7d17",
    },
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        return pages[`./Pages/${name}.vue`]
    },
    setup({el, App, props, plugin}) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: "en",
            messages: localeMessages,
        });

        return createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(
                createGtm({
                    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
                    enabled: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED === 'true' && import.meta.env.PROD,
                    debug: import.meta.env.NODE_ENV !== 'production',
                })
            )
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(dayjs, {locale: currentLocale})
            .use(i18n)
            .use(Notifications)
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    // plugins: [messageToHTMLPlugin, createAutoAnimatePlugin({duration: 150})],
                    locales: {nl},
                    locale: "nl",
                    globalInjection: true,
                    icons: {
                        // include supporting icons from @formkit/icons
                        arrowDown,
                        arrowUp,
                        close,
                        checkboxDecorator: check,
                        fileItem: fileDoc,
                        fileRemove: close,
                        noFiles: fileDoc,
                        radioDecorator: circle,
                        select: down,
                        spinner,
                        star,
                        trash,
                    },
                })
            )
            .mount(el);
    },
}).then((app) => cleanApp(app));

const cleanApp = () => {
    app.removeAttribute("data-page");
};

function messageToHTMLPlugin(node) {
    const icon =
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.818" viewBox="0 0 16 13.818"><path id="warning_FILL0_wght400_GRAD0_opsz48" d="M10.018,11.436ZM2,17.818,10,4l8,13.818Zm7.527-4.145h1.091V9.6H9.527Zm.545,2.018a.529.529,0,1,0-.391-.155A.53.53,0,0,0,10.073,15.691ZM3.891,16.727H16.109L10,6.182Z" transform="translate(-2 -4)" fill="#c30000"/></svg>';

    node.on("created", () => {
        const original = node.props.definition.schema;
        node.context.warningIcon = icon;
        node.props.definition.schema = (extensions) => {
            if (!extensions.message) {
                extensions.message = {
                    attrs: {
                        innerHTML: '$warningIcon + " " + $message.value',
                    },
                    children: null,
                };
            }
            return original(extensions);
        };
    });
}
