<script setup>
import PublicLayout from "@/Layouts/PublicLayout.vue";

import { computed, inject } from "vue";

import Breadcrumb from "@/Components/Breadcrumb.vue";
import Button from "@/Components/Inputs/Button.vue";
import Price from "@/Components/Product/Price.vue";
import ProductGrid from "@/Components/Product/ProductGrid.vue";
import ProductImageSlider from "@/Components/Product/ProductImageSlider.vue";
import { useCartStore } from "@/stores/cart";
import { usePage } from "@inertiajs/vue3";

const route = inject("route");

const props = defineProps({
    page: Object,
    product: Object,
    related_products: Object,
    articles: Object,
});

const shoppingCart = useCartStore();

const productType = computed(() => {
    if (props.product.is_discount_active) {
        return "discount";
    }
    return "default";
});

const productCategories = computed(() => {
    return props.product.schema_data.map((category) => category.id);
});
const productUncombinable = computed(() => {
    return props.product.schema_data
        .map((productCategories) => productCategories.uncombinable.map((uncombinable) => uncombinable.id))
        .flat(2);
});

const uncombinableProducts = computed(() => {
    return shoppingCart.products.filter((product) => {
        if (
            product.schema_data
                .map((productCategories) => productCategories.uncombinable.map((uncombinable) => uncombinable.id))
                .flat(2)
                .some((element) => productCategories.value.includes(element))
        ) {
            return true;
        } else if (
            product.schema_data
                .map((category) => category.id)
                .some((element) => productUncombinable.value.includes(element))
        ) {
            return true;
        }

        return false;
    });
});

const auth = usePage().props.auth;
</script>

<template>
    <PublicLayout
        :image="product.og_image"
        :description="product.page?.seo_description"
        :title="product.page?.seo_title"
    >
        <Breadcrumb
            :pages="[
                { name: $t('Products'), href: route('public.products.index'), current: false },
                {
                    name: product.title,
                    href: route('public.products.show', { product: product.page.slug }),
                    current: true,
                },
            ]"
        />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <div class="grid grid-cols-1 gap-12 sm:grid-cols-12 sm:gap-4 md:gap-12">
                <div class="sm:col-span-5">
                    <ProductImageSlider :media="product.media" />
                </div>

                <div class="flex flex-col gap-8 sm:col-span-7">
                    <div
                        :class="[['default'].includes(productType) ? 'bg-lightGray-400' : 'bg-white']"
                        class="w-full rounded-3xl px-4 py-6 sm:rounded-xl"
                    >
                        <div class="flex flex-col gap-12">
                            <span class="px-1 text-base font-bold">{{ product.title }}</span>

                            <div class="flex flex-col">
                                <span
                                    v-if="!product.min_order"
                                    class="text-sm"
                                >{{
                                    $t(Object.keys(product.properties)[0])
                                }}</span>
                                <span
                                    v-else
                                    class="text-sm"
                                >Vanaf: {{ product.min_order }}</span>
                                <Price
                                    :discount_price="shoppingCart.getProductDiscount(product)"
                                    :price="(product.min_order ?? 1) * product.price_including_vat"
                                />
                            </div>
                            <div class="">
                                <Button
                                    :disabled="!!uncombinableProducts.length"
                                    full-width
                                    @click="shoppingCart.interactWithProduct(product)"
                                >
                                    {{ $t("Order now") }}
                                </Button>

                                <div
                                    v-if="uncombinableProducts.length"
                                    class="mt-3"
                                >
                                    <p class="text-sm">
                                        {{ $t("Not available with product(s):") }}
                                    </p>

                                    <div class="my-3 flex flex-wrap gap-2">
                                        <span
                                            v-for="product in uncombinableProducts"
                                            class="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
                                        >
                                            {{ product.title }}
                                            <Button
                                                class="ml-0.5 inline-flex size-4 shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                                disable-style
                                                type="button"
                                                @click="shoppingCart.deleteProduct(product.id)"
                                            >
                                                <svg
                                                    class="size-2"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 8 8"
                                                >
                                                    <path
                                                        d="M1 1l6 6m0-6L1 7"
                                                        stroke-linecap="round"
                                                        stroke-width="1.5"
                                                    />
                                                </svg>
                                            </Button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex w-full flex-wrap gap-3">
                        <div
                            v-for="allergen in product.allergens"
                            class="flex flex-col content-center items-center"
                        >
                            <img
                                :alt="allergen"
                                :src="'/allergens/' + allergen + '.svg'"
                                class="size-12"
                            >

                            <span class="w-12 text-center text-sm">{{ $t(allergen) }}</span>
                        </div>
                    </div>

                    <div class="flex w-full flex-col gap-8">
                        <div
                            v-if="product.description"
                            class="flex flex-col gap-4"
                        >
                            <h3 class="text-2xl font-bold">
                                {{ $t("Description") }}
                            </h3>

                            <div
                                class="prose text-dark"
                                v-html="product.description"
                            />
                        </div>

                        <div
                            v-if="product.preparation"
                            class="flex flex-col gap-4"
                        >
                            <h3 class="text-2xl font-bold">
                                {{ $t("Preparation") }}
                            </h3>

                            <div
                                class="prose text-dark"
                                v-html="product.preparation"
                            />
                        </div>
                    </div>

                    <div class="flex w-full flex-wrap gap-8">
                        <div class="flex flex-col gap-4">
                            <h3 class="text-2xl font-bold">
                                {{ $t("Questions?") }}
                            </h3>

                            <p class="prose text-dark">
                                {{ $t("Do you have any questions or can't find what you're looking for?") }}
                            </p>

                            <Button
                                class="mt-2"
                                routeName="public.contact"
                            >
                                {{ $t("Contact") }}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto mt-30 max-w-4xl">
                <ProductGrid
                    :products="related_products"
                    :button-title="$t('All related products')"
                    :title="$t('Related products')"
                    type="related"
                />
            </div>
        </div>

        <div
            v-if="auth?.administrator"
            class="fixed bottom-15 left-15"
        >
            <Button
                no-inertia
                class="mt-2"
                :href="'/admin/products/' + product.id + '/edit'"
            >
                Bewerk product
            </Button>
        </div>
    </PublicLayout>
</template>
