<script setup>
import PublicLayout from "@/Layouts/PublicLayout.vue";

import Button from "@/Components/Inputs/Button.vue";
import {useGtm} from "@gtm-support/vue-gtm";
import {CheckIcon, ClipboardDocumentIcon} from "@heroicons/vue/24/outline";
import {useStorage} from "@vueuse/core";
import {notify} from "notiwind";
import {computed, inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

const gtm = useGtm();
const {t} = useI18n();

const route = inject("route");

const props = defineProps({
    order: Object,
    paymentStatus: String,
    analyticsTriggered: [Boolean, String, Number],
    coupon: String,
});

const paymentStatus = ref(props.paymentStatus);

const retryingPayment = ref(false);

const paymentPolling = ref(null);

const paymentFailed = computed(() => {
    return ["Cancelled", "Expired", "Failed"].includes(paymentStatus.value);
});

const paymentWaiting = computed(() => {
    return ["Pending", "Initial", "Open"].includes(paymentStatus.value);
});

const paymentSuccess = computed(() => {
    return paymentStatus.value === "Paid";
});

const purchaseCompleted = useStorage("purchase_completed", false);

function trackAnalytics() {
    if (paymentSuccess.value && !purchaseCompleted.value && !!route().params?.redirected && !props.analyticsTriggered) {
        purchaseCompleted.value = true;
        gtm.trackEvent({
            event: "purchase",
            ecommerce: {
                transaction_id: props.order.uuid,
                currency: "EUR",
                value: props.order.total_paid,
                coupon: props.order.coupon,
                items: props.order.products,
            },
        });
    }
}

const title = computed(() => {
    if (paymentSuccess.value) {
        // return trans('Thank you for your order!');
    }

    if (paymentFailed.value) {
        // return trans('Payment failed');
    }

    // return trans('One moment please...');
});

onMounted(() => {
    trackAnalytics();
    if (paymentWaiting.value) {
        paymentPolling.value = setInterval(() => {
            checkPayment();
        }, 5000);
    }
});

const checkPayment = async () => {
    if (!paymentWaiting.value) {
        return;
    }

    try {
        const response = await axios.get(
            route("api.commerce.payment-status", {
                order: props.order.uuid,
            })
        );

        paymentStatus.value = response.data;

        if (paymentSuccess.value || paymentFailed.value) {
            clearInterval(paymentPolling.value);
            location.reload();
        }
    } catch (error) {
        console.error(error);
    }
};

const retryPayment = async () => {
    if (!paymentFailed.value) {
        return;
    }

    try {
        retryingPayment.value = true;
        const response = await axios.post(route("api.commerce.retry-payment"), {
            order: props.order.uuid,
        });

        window.location.href = response.data.redirect_url;
    } catch (error) {
        console.error(error);
    } finally {
        retryingPayment.value = false;
    }
};

async function copyDiscountCode(code) {
    try {
        await navigator.clipboard.writeText(code);
        notify(
            {
                type: "success",
                title: t("Discount code copied"),
            },
            3000
        );
    } catch ($e) {
        notify(
            {
                type: "warning",
                title: t("Discount code could not be copied"),
            },
            3000
        );
    }
}
</script>

<template>
    <PublicLayout>
        <div
            v-if="paymentSuccess"
            class="mx-auto max-w-7xl px-4 pb-8 pt-10 pt-24 sm:px-6"
        >
            <div class="mx-auto max-w-3xl">
                <div class="relative flex w-full flex-col gap-6 rounded-2xl bg-lightGray-400 px-5 py-10 text-center">
                    <div class="flex w-full justify-center">
                        <div
                            class="absolute -top-14 flex size-28 items-center justify-center rounded-full border-8 border-lightGray-400 bg-secondary-400"
                        >
                            <CheckIcon class="size-16 text-primary-400"/>
                        </div>
                    </div>
                    <h1 class="mt-4 text-2xl font-bold">
                        {{ $t("Thank you for your order!") }}
                    </h1>
                    <div>
                        <div class="mx-auto flex max-w-md flex-col justify-center">
                            <div class="mx-6 flex items-center justify-evenly sm:mx-12">
                                <div
                                    class="flex inline-flex size-10 items-center justify-center rounded-full bg-secondary-400 font-medium text-white"
                                >
                                    <CheckIcon class="size-6"/>
                                </div>
                                <div class="flex grow items-center">
                                    <div class="mx-auto h-1.5 w-full bg-secondary-400"/>
                                </div>
                                <div
                                    class="flex inline-flex size-10 items-center justify-center rounded-full bg-secondary-400 font-medium text-white"
                                >
                                    <div class="flex size-9 items-center justify-center rounded-full bg-white">
                                        <div class="size-2.5 rounded-full bg-primary-400"/>
                                    </div>
                                </div>
                                <div class="flex grow items-center">
                                    <div class="mx-auto h-1.5 w-full bg-primary-400"/>
                                </div>
                                <div
                                    class="flex inline-flex size-10 items-center justify-center rounded-full bg-primary-400 font-medium text-white"
                                >
                                    <div class="flex size-9 items-center justify-center rounded-full bg-white"/>
                                </div>
                            </div>
                            <div class="flex pt-4 text-sm sm:text-lg">
                                <div class="basis-1/3">
                                    <div class="mx-2 h-full">
                                        {{ $t("Order received") }}
                                    </div>
                                </div>
                                <div class="basis-1/3">
                                    <div class="mx-2 h-full">
                                        {{ $t("Preparing order") }}
                                    </div>
                                </div>
                                <div class="basis-1/3">
                                    <div class="mx-2 h-full">
                                        {{ $t("Ready for delivery") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <div class="flex flex-col gap-2 rounded-2xl bg-primary-400 px-12 py-2">
                            <div>
                                <p>{{ $t("Ordernumber:") }}</p>
                                <span class="text-2xl font-bold">{{ order.order_number }}</span>
                            </div>

                            <div>
                                <p>{{ $t("Order date:") }}</p>
                                <span>{{ order.order_date }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="mx-auto max-w-xl">
                        <div>
                            {{
                                $t(
                                    "We have received your order and are now preparing it for delivery, you can expect your order at:"
                                )
                            }}
                        </div>
                        <div class="mt-2">
                            <span class="text-2xl font-bold">{{ order.delivery_date }}</span>
                        </div>
                    </div>

                    <div
                        v-if="coupon"
                        class="flex justify-center"
                    >
                        <div class="mb-5 flex flex-col gap-2 rounded-2xl px-12 py-2">
                            <div>
                                <p class="text-lg">
                                    Een
                                    <template v-if="coupon.type === 'percentage'">
                                        <span class="font-bold">{{ coupon.value }}%</span>
                                    </template>
                                    <template v-else-if="coupon.type === 'fixed'">
                                        <span class="font-bold">{{ $n(coupon.value / 100, "currency", "nl") }}</span>
                                    </template>
                                    coupon als bedankje!
                                </p>
                                <div>
                                    <div
                                        class="relative mt-2 flex w-full items-center justify-center gap-1 rounded-2xl bg-primary-400 px-4 py-2"
                                    >
                                        <div class="hidden w-8 sm:block"/>
                                        <span class="text-2xl font-bold">{{ coupon.code }}</span>
                                        <button
                                            class="size-8 rounded-full p-1 hover:bg-primary-300"
                                            @click="copyDiscountCode(coupon.code)"
                                        >
                                            <ClipboardDocumentIcon class="size-full"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex -translate-y-[19px] justify-center">
                    <Button
                        routeName="public.home"
                        size="large"
                    >
                        {{ $t("Back to home") }}
                    </Button>
                </div>
            </div>
        </div>

        <div
            v-else-if="paymentFailed"
            class="flex flex-col items-center justify-center space-y-5 lg:bg-[#E3E3E3] lg:p-15"
        >
            <p class="text-xl lg:col-span-2">
                Als dit een vergissing was, kunt u toch betalen door op de onderstaande knop te klikken.
            </p>

            <Button
                :disabled="retryingPayment"
                class="lg:w-1/3"
                @click="retryPayment"
            >
                Betaling opnieuw proberen
            </Button>
        </div>
    </PublicLayout>
</template>
