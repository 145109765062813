<script setup>
import paymentMethods from '@/assets/images/payment-methods.webp';

import ClockIcon from '@/assets/images/footer-clock.svg';
import ContactIcon from '@/assets/images/footer-contact.svg';
import PaymentIcon from '@/assets/images/footer-payment.svg';
import ShopIcon from '@/assets/images/footer-shop.svg';
import ShoppingIcon from '@/assets/images/footer-shopping.svg';
import Button from '@/Components/Inputs/Button.vue';
import { usePage } from '@inertiajs/vue3';

const companyDetails = usePage().props.company_details;
</script>

<template>
    <footer
        aria-labelledby="footer-heading"
        class="bg-secondary-400"
    >
        <h2
            id="footer-heading"
            class="sr-only"
        >
            Footer
        </h2>
        <div class="mx-auto max-w-7xl px-4 pb-8 pt-20 sm:px-6 sm:pt-24">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 xl:grid-cols-3">
                <div>
                    <h3 class="flex items-center text-2xl font-bold uppercase leading-6 text-primary-400">
                        <img
                            :alt="$t('Shop')"
                            :src="ShopIcon"
                            class="mr-4 size-9"
                            height="24"
                            width="24"
                        >
                        {{ $t('about us') }}
                    </h3>
                    <ul
                        class="mt-6 space-y-4"
                        role="list"
                    >
                        <li>
                            <Button
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                                disable-style
                                routeName="public.about-us"
                            >
                                {{ $t('About de vers specialist') }}
                            </Button>
                        </li>
                        <li>
                            <Button
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                                disable-style
                                routeName="public.faq"
                            >
                                {{ $t('Frequently asked questions') }}
                            </Button>
                        </li>
                        <li>
                            <Button
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                                disable-style
                                no-inertia
                                routeName="public.terms-and-conditions"
                            >
                                {{ $t('Terms and conditions') }}
                            </Button>
                        </li>
                        <li>
                            <Button
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                                disable-style
                                no-inertia
                                routeName="public.privacy-statement"
                            >
                                {{ $t('Privacy Statement') }}
                            </Button>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="flex items-center text-2xl font-bold uppercase leading-6 text-primary-400">
                        <img
                            :alt="$t('Contact')"
                            :src="ContactIcon"
                            class="mr-4 size-9"
                            height="24"
                            width="24"
                        >

                        {{ $t('Contact') }}
                    </h3>
                    <ul
                        class="mt-6 space-y-4"
                        role="list"
                    >
                        <li>
                            <a
                                :href="'mailto:' + companyDetails.email_address"
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                            >{{ $t('Send us a mail') }}</a>
                        </li>
                        <li class="flex flex-col gap-1">
                            <span class="text-md leading-6 text-gray-300">{{ companyDetails.street }}, {{ companyDetails.postal_code }}
                                {{ companyDetails.city }}</span>

                            <a
                                :href="'tel:' + companyDetails.phone_number"
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                            >{{ companyDetails.phone_number }}</a>
                        </li>

                        <li class="flex flex-col gap-1">
                            <span class="text-md leading-6 text-gray-300">{{ companyDetails.street2 }}, {{ companyDetails.postal_code2 }}
                                {{ companyDetails.city2 }}</span>
                            <a
                                :href="'tel:' + companyDetails.phone_number2"
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                            >{{ companyDetails.phone_number2 }}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="flex items-center text-2xl font-bold uppercase leading-6 text-primary-400">
                        <img
                            :alt="$t('Shop')"
                            :src="ShoppingIcon"
                            class="mr-4 size-9"
                            height="24"
                            width="24"
                        >

                        {{ $t('Order online') }}
                    </h3>
                    <ul
                        class="mt-6 space-y-4"
                        role="list"
                    >
                        <li>
                            <Button
                                class="text-md leading-6 text-gray-300 hover:text-primary-400"
                                disable-style
                                routeName="public.supply-and-delivery"
                            >
                                {{ $t('Supply and Delivery') }}
                            </Button>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="flex items-center text-2xl font-bold uppercase leading-6 text-primary-400">
                        <img
                            :alt="$t('Clock')"
                            :src="ClockIcon"
                            class="mr-4 size-9"
                            height="24"
                            width="24"
                        >

                        <span class="uppercase">{{ $t('Opening hours') }}</span>
                    </h3>
                    <ul
                        class="mt-6 space-y-4"
                        role="list"
                    >
                        <li>
                            <span class="text-md whitespace-pre-line leading-6 text-gray-300">{{
                                companyDetails.opening_hours
                            }}</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="flex items-center text-2xl font-bold uppercase leading-6 text-primary-400">
                        <img
                            :alt="$t('Payment')"
                            :src="PaymentIcon"
                            class="mr-4 size-9"
                            height="24"
                            width="24"
                        >

                        {{ $t('Payment methods') }}
                    </h3>
                    <div class="mt-6 space-y-4">
                        <img
                            :alt="$t('Payment methods')"
                            :src="paymentMethods"
                            height="24"
                            width="212"
                        >
                    </div>
                </div>
            </div>
            <div class="mt-16 border-t border-primary-400 pt-8">
                <p class="text-md mt-8 text-center leading-5 text-white md:mt-0">
                    2023 © De Vers Specialist - {{ $t('Realised by:') }} <a href="https://strixi.nl">STRIXI</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<style scoped></style>
