<script setup>
import Button from "@/Components/Inputs/Button.vue";
import ProductGrid from "@/Components/Product/ProductGrid.vue";
import ProductFilter from "@/Components/ProductFilter.vue";
import {ref} from "vue";

const props = defineProps({
    discountedProducts: Array,
    categoriesWithProducts: Object,
    query: Object,
});

props.query.sort = "default";

const processing = ref(false);

const toppersId = props.categoriesWithProducts.find((i) => i.title === "Toppers")?.id;
</script>

<template>
    <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
        <div class="grid grid-cols-1 gap-8 lg:grid-cols-4">
            <div class="self-start lg:sticky lg:top-40">
                <ProductFilter
                    v-model:processing="processing"
                    :categories="categoriesWithProducts"
                    :query="query"
                />
            </div>

            <div class="flex flex-col gap-10 lg:col-span-3">
                <div class="hidden items-center justify-between sm:flex">
                    <div>
                        <span>{{ $t("Amount of products:") }}
                            <b>{{
                                    categoriesWithProducts.reduce((partialSum, a) => partialSum + a?.products.length, 0)
                                }}</b></span>
                    </div>
                    <div class="flex items-center">
                        <span>{{ $t("Sory by:") }}</span>
                        <div>
                            <select
                                id="location"
                                v-model="query.sort"
                                class="form-select ml-2 block w-full rounded-md border-0 bg-transparent py-0 pr-8 font-bold text-gray-900"
                                name="location"
                            >
                                <option
                                    selected
                                    value="default"
                                >
                                    {{ $t("Default") }}
                                </option>
                                <option value="price">
                                    {{ $t("Price: Low - High") }}
                                </option>
                                <option value="price-desc">
                                    {{ $t("Price: High - Low") }}
                                </option>

                                <option value="sales">
                                    {{ $t("Most sold") }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <ProductGrid
                    :button-title="'Meer Aanbiedingen'"
                    :products="discountedProducts"
                    button-route="public.categories.discounts.show"
                    title="Aanbiedingen"
                    type="discount"
                />

                <template
                    v-for="(categorieProduct, key) in categoriesWithProducts"
                    :key="key"
                    v-if="categoriesWithProducts.reduce((partialSum, a) => partialSum + a?.products.length, 0)"
                >
                    <ProductGrid
                        :button-params="{ category: categorieProduct.page?.slug }"
                        :button-title="'Meer van ' + categorieProduct?.title"
                        :products="categorieProduct.products"
                        :title="categorieProduct?.title"
                        :type="categorieProduct.id === toppersId ? 'toppers' : undefined"
                        button-route="public.categories.show"
                    />
                </template>

                <div
                    v-else
                    class="mt-20"
                >
                    <div class="text-4xl font-bold">
                        <span> {{ $t("No products found") }} </span>
                    </div>
                </div>

                <div class="mt-10 flex items-center justify-center">
                    <Button
                        class="text-xl font-bold uppercase hover:text-primary-400"
                        disable-style
                        route-name="public.products.index"
                    >
                        {{ $t("View all products") }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
