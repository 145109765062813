<script setup>
import {computed} from 'vue';

import Hero from '@/PageBlocks/Hero.vue';
import Paragraph from '@/PageBlocks/Paragraph.vue';
import Heading from '@/PageBlocks/Heading.vue';
import MultiProduct from '@/PageBlocks/MultiProduct.vue';
import SingleProduct from '@/PageBlocks/SingleProduct.vue';
import MultiCategory from '@/PageBlocks/MultiCategory.vue';

const props = defineProps({
    blockList: Object,
});

const pageBlocks = computed(() => {
    return props.blockList;
});
</script>

<template>
    <div class="mb-40">
        <template v-for="block in pageBlocks">
            <Hero
                v-if="block.type === 'hero_block'"
                :data="block.data"
            />
            <Paragraph
                v-else-if="block.type === 'paragraph_block'"
                :data="block.data"
            />
            <Heading
                v-else-if="block.type === 'heading_block'"
                :data="block.data"
            />
            <MultiProduct
                v-else-if="block.type === 'multi_product_block'"
                :data="block.data"
            />
            <SingleProduct
                v-else-if="block.type === 'single_product_block'"
                :data="block.data"
            />
            <MultiCategory
                v-else-if="block.type === 'multi_category_block'"
                :data="block.data"
            />
        </template>
    </div>
</template>

<style scoped></style>
