<script setup>
import { MinusIcon, PlusIcon } from "@heroicons/vue/20/solid";
import { computed } from "vue";

function increment() {
    amount.value = ++amount.value;
}

function subtract() {
    amount.value = --amount.value;
}

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
    modelValue: Number,
    responsive: Boolean,
    allowZero: Boolean,
    min: Number,
    max: Number,
    size: {
        type: String,
        default: "default",
        validator(value) {
            // The value must match one of these strings
            return ["default", "small", "large", "responsive"].includes(value);
        },
    },
    variant: {
        type: String,
        default: "default",
        validator(value) {
            // The value must match one of these strings
            return ["default", "dense"].includes(value);
        },
    },
});

const amount = computed({
    get() {
        if (typeof props.modelValue !== "undefined") {
            return props.modelValue;
        }
        //Trigger default if undefined because of input bug
        amount.value = 1;
        return 1;
    },
    set(value) {
        if (!props.allowZero && value === 0) value = 1;
        else if (value < 0) value = 0;

        const max = props.max ?? 100;

        const min = props.min ?? 1;

        if (value > max) {
            value = value - 1;
        }

        if (value !== 0 && value < min) {
            value = value + 1;
        }

        emit("update:modelValue", value);
    },
});
</script>
<template>
    <div
        v-if="['dense'].includes(variant)"
        class="flex items-center rounded-full border-0 bg-lightGray-300 p-0 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600"
    >
        <button
            class="flex size-7 items-center justify-center rounded-full bg-primary-400 text-2xl text-white md:left-auto"
            @click="subtract"
        >
            <minus-icon class="size-5 text-black" />
        </button>
        <input
            v-model="amount"
            class="form-input size-8 border-0 bg-transparent p-0 text-center sm:w-10"
            :max="max ?? 100"
            :min="min ?? 1"
            type="number"
        >
        <button
            class="flex size-7 items-center justify-center rounded-full bg-primary-400 text-base text-white md:left-auto"
            @click="increment"
        >
            <plus-icon class="size-5 text-black" />
        </button>
    </div>

    <div
        v-else
        class="flex items-center gap-2"
    >
        <button
            :class="[
                ['small'].includes(size) ? 'size-9' : '',
                ['large'].includes(size) ? 'size-12' : '',
                ['default'].includes(size) ? 'size-10' : '',
                ['responsive'].includes(size) ? 'size-9 sm:size-10' : '',
            ]"
            class="flex items-center justify-center rounded-full bg-primary-400 text-2xl text-white md:left-auto"
            @click="subtract"
        >
            <minus-icon class="size-7 text-black" />
        </button>
        <input
            v-model="amount"
            :class="[
                ['small'].includes(size) ? 'h-9 w-24 ' : '',
                ['large'].includes(size) ? 'h-12 w-32 ' : '',
                ['default'].includes(size) ? 'h-10 w-32 ' : '',
                ['responsive'].includes(size) ? 'h-9 w-24 sm:h-10 sm:w-32' : '',
            ]"
            class="form-input rounded-full border-0 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600"
            :max="max ?? 100"
            :min="min ?? 1"
            type="number"
        >
        <button
            :class="[
                ['small'].includes(size) ? 'size-9' : '',
                ['large'].includes(size) ? 'size-12' : '',
                ['default'].includes(size) ? 'size-10' : '',
                ['responsive'].includes(size) ? 'size-9 sm:size-10' : '',
            ]"
            class="flex items-center justify-center rounded-full bg-primary-400 text-base text-white md:left-auto"
            @click="increment"
        >
            <plus-icon class="size-7 text-black" />
        </button>
    </div>
</template>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
