<script setup>
import Placeholder from "@/assets/images/product-placeholder.webp";

import { computed, inject, onMounted, ref } from "vue";
import {
    Dialog,
    DialogPanel,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import Button from "@/Components/Inputs/Button.vue";
import NumberInput from "@/Components/Inputs/NumberInput.vue";
import Price from "@/Components/Product/Price.vue";
import { useCartStore } from "@/stores/cart";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";

const route = inject("route");

const shoppingCart = useCartStore();

const product = ref();

const amount = ref(1);
const extraProductIds = ref([]);

const loadConfigData = () => {
    return axios
        .get(
            route("api.commerce.load-product-configurator-data", {
                product: shoppingCart.productConfiguratorProduct.id,
            })
        )
        .then((response) => {
            product.value = response.data;

            amount.value = response.data.min_order ?? 1;

            customProduct.value = response.data;
            for (const key in product.value.variant_filter) {
                if (product.value.variant_filter.hasOwnProperty(key)) {
                    const value = product.value.variant_filter[key].toLowerCase();
                    const type = key.toLowerCase();
                    selected.value[type] = value;
                    rememberPossibleCombination.value[type] = value;
                }
            }
        });
};

onMounted(() => {
    loadConfigData();
});

function closeConfigurator() {
    shoppingCart.setProduct(productId.value, amount.value);

    const extras = product.value.extras.filter((data) => data.is_selected);
    extras.forEach((extra) => {
        shoppingCart.setProduct(extra.id, extra.selected_amount ?? amount.value);
    });

    shoppingCart.closeProductConfigurator();
    shoppingCart.cartSidebar = true;
}

const payablePrice = computed(() => {
    const price = {
        discount: 0,
        original: 0,
    };

    let newProduct = product.value;

    if (productId.value !== newProduct.id) {
        newProduct = newProduct.children.find((data) => data.id === productId.value);
    }

    const extras = product.value.extras.filter((data) => data.is_selected);

    let extrasPrice = 0;
    let extrasPriceDiscount = 0;

    extras.forEach((extra) => {
        extrasPrice += extra.price_including_vat * (extra.selected_amount ?? amount.value);
        extrasPriceDiscount +=
            (extra.is_discount_active ? extra.discounted_price_including_vat : extra.price_including_vat) *
            (extra.selected_amount ?? amount.value);
    });

    price.original = amount.value * newProduct.price_including_vat + extrasPrice;
    price.discount =
        amount.value *
            (newProduct.is_discount_active
                ? newProduct.discounted_price_including_vat
                : newProduct.price_including_vat) +
        extrasPriceDiscount;

    if (price.discount === price.original) price.discount = null;

    return price;
});

const selectValues = computed(() => {
    const filter = {};

    const variantList = product.value ? [product.value, ...product.value?.children] : [];

    variantList.map((child) => {
        for (const key in child.variant_filter) {
            if (child.variant_filter.hasOwnProperty(key)) {
                const value = child.variant_filter[key].toLowerCase();
                const type = key.toLowerCase();

                (filter[type] ||= []).push(value);
                filter[type] = filter[type].filter((item, index) => filter[type].indexOf(item) === index);
            }
        }
    });

    return filter;
});

const extractedProduct = computed(() => {
    const variantList = product.value ? [product.value, ...product.value?.children] : [];

    return variantList.find((product) => {
        const list = {};
        for (const key in product.variant_filter) {
            if (product.variant_filter.hasOwnProperty(key)) {
                const value = product.variant_filter[key].toLowerCase();
                const type = key.toLowerCase();
                list[type] = value;
            }
        }

        return (
            Object.keys(selected.value).length &&
            Object.entries(list).toString() === Object.entries(selected.value).toString()
        );
    });
});

const customProduct = ref(null);

const selectedProduct = computed(() => {
    return extractedProduct.value ?? customProduct.value ?? shoppingCart.productConfiguratorProduct;
});

const productId = computed(() => {
    return selectedProduct.value?.id;
});

function findCombinationProduct(key, value) {
    const variantList = product.value ? [product.value, ...product.value?.children] : [];
    const newSelected = Object.assign({}, selected.value);

    newSelected[key] = value;

    return variantList.find((product) => {
        const list = {};
        for (const key in product.variant_filter) {
            if (product.variant_filter.hasOwnProperty(key)) {
                const value = product.variant_filter[key].toLowerCase();
                const type = key.toLowerCase();
                list[type] = value;
            }
        }
        return Object.entries(list).toString() === Object.entries(newSelected).toString();
    });
}

const selected = ref({});
const rememberPossibleCombination = ref({});
</script>

<template>
    <TransitionRoot
        :show="shoppingCart.productConfigurator"
        as="template"
    >
        <Dialog
            as="div"
            class="relative z-50"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-50 flex min-h-full justify-center sm:items-center">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <DialogPanel
                        v-if="product"
                        class="relative flex w-full flex-col bg-white text-left shadow-xl transition-all sm:max-h-[80vh] sm:max-w-screen-md sm:rounded-xl"
                    >
                        <div class="absolute right-0 top-0 z-50 flex w-full justify-end pr-4 pt-4">
                            <button
                                class="rounded-md bg-white text-gray-800 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                type="button"
                                @click="shoppingCart.closeProductConfigurator()"
                            >
                                <span class="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    class="size-8"
                                />
                            </button>
                        </div>

                        <div class="right-0 top-0 z-40 flex w-full gap-5 p-4 sm:p-8">
                            <img
                                alt="Product image"
                                :src="product.first_media?.src ?? Placeholder"
                                class="aspect-square h-25 rounded-lg object-cover"
                            >
                            <div class="flex items-center font-bold">
                                <span>
                                    {{ product.title }}
                                </span>
                            </div>
                        </div>
                        <div class="min-h-100 grow overflow-y-auto px-4 pb-20 pt-4 sm:px-8 sm:pt-8">
                            <div class="flex flex-col gap-4">
                                <template v-if="product.children?.length">
                                    <div class="mt-2 text-xl font-bold uppercase">
                                        {{ $t("Make your decision:") }}
                                    </div>

                                    <template v-for="(values, key) in selectValues">
                                        <Listbox
                                            v-model="selected[key]"
                                            as="div"
                                        >
                                            <ListboxLabel
                                                class="block text-lg font-medium leading-6 text-gray-900 first-letter:capitalize"
                                            >
                                                {{ key }}
                                            </ListboxLabel>
                                            <div class="relative mt-2">
                                                <ListboxButton
                                                    class="flex h-20 w-full cursor-default items-center justify-between rounded-lg bg-lightGray-400 p-2 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-500 sm:pl-6"
                                                    @click="
                                                        (selected = rememberPossibleCombination), (customProduct = null)
                                                    "
                                                >
                                                    <div class="flex">
                                                        <ChevronDownIcon class="size-6" />

                                                        <div
                                                            class="ml-3 text-sm font-medium leading-6 text-gray-900 first-letter:capitalize"
                                                        >
                                                            <span v-if="selected[key]">{{ selected[key] }}</span>
                                                            <span v-else>{{ $t("Select") }} {{ key }}</span>
                                                        </div>
                                                    </div>
                                                </ListboxButton>

                                                <transition
                                                    leave-active-class="transition ease-in duration-100"
                                                    leave-from-class="opacity-100"
                                                    leave-to-class="opacity-0"
                                                >
                                                    <ListboxOptions class="absolute top-0 z-50 w-full">
                                                        <div
                                                            class="mb-10 flex max-h-58 flex-col gap-2 overflow-auto rounded-lg bg-white text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                        >
                                                            <ListboxOption
                                                                v-for="value in values"
                                                                :key="values"
                                                                v-slot="{ active, selected }"
                                                                :disabled="!findCombinationProduct(key, value)"
                                                                :value="value"
                                                                as="template"
                                                            >
                                                                <li
                                                                    :class="[
                                                                        active
                                                                            ? 'bg-primary-400 text-white'
                                                                            : 'text-gray-900',
                                                                        selected
                                                                            ? 'bg-primary-400 text-white'
                                                                            : 'bg-lightGray-400 text-gray-900',
                                                                    ]"
                                                                    class="relative flex h-20 min-h-20 w-full items-center justify-between overflow-hidden rounded-lg p-2 sm:pl-6"
                                                                >
                                                                    <div
                                                                        v-if="!findCombinationProduct(key, value)"
                                                                        class="absolute left-0 top-0 flex size-full items-center justify-center bg-lightGray-400 bg-opacity-75 text-lg"
                                                                    >
                                                                        <span>{{
                                                                            $t("Not available in this combination")
                                                                        }}</span>
                                                                    </div>

                                                                    <div class="flex">
                                                                        <CheckIcon
                                                                            :class="selected ? '' : 'opacity-0'"
                                                                            class="size-6"
                                                                        />

                                                                        <div
                                                                            class="ml-3 text-sm leading-6 first-letter:capitalize"
                                                                        >
                                                                            <span>{{ value }} </span>

                                                                            <template
                                                                                v-if="
                                                                                    !!findCombinationProduct(
                                                                                        key,
                                                                                        value
                                                                                    ) &&
                                                                                        shoppingCart.getVariantPriceAddition(
                                                                                            selectedProduct,
                                                                                            findCombinationProduct(
                                                                                                key,
                                                                                                value
                                                                                            )
                                                                                        ) !== 0
                                                                                "
                                                                            >
                                                                                {{
                                                                                    " ( " +
                                                                                        (shoppingCart.getVariantPriceAddition(
                                                                                            selectedProduct,
                                                                                            findCombinationProduct(
                                                                                                key,
                                                                                                value
                                                                                            )
                                                                                        ) >= 0
                                                                                            ? "+ "
                                                                                            : "- ") +
                                                                                        $n(
                                                                                            Math.abs(
                                                                                                shoppingCart.getVariantPriceAddition(
                                                                                                    selectedProduct,
                                                                                                    findCombinationProduct(
                                                                                                        key,
                                                                                                        value
                                                                                                    )
                                                                                                )
                                                                                            ) / 100,
                                                                                            "currency",
                                                                                            "nl"
                                                                                        ) +
                                                                                        " )"
                                                                                }}
                                                                            </template>
                                                                        </div>
                                                                    </div>

                                                                    <img
                                                                        alt="Product image"
                                                                        :src="
                                                                            findCombinationProduct(key, value)
                                                                                ?.first_media?.src ??
                                                                                product.first_media?.src ??
                                                                                Placeholder
                                                                        "
                                                                        class="aspect-square h-full rounded-lg object-cover"
                                                                    >
                                                                </li>
                                                            </ListboxOption>
                                                        </div>
                                                    </ListboxOptions>
                                                </transition>
                                            </div>
                                        </Listbox>
                                    </template>

                                    <span
                                        v-if="
                                            Object.keys(selectValues).length &&
                                                (!!product.children.find(
                                                    (variant) => !Object.keys(variant.variant_filter).length
                                                ) ||
                                                    !Object.keys(product.variant_filter).length)
                                        "
                                        class="block text-lg font-medium leading-6 text-gray-900 first-letter:capitalize"
                                    >{{ $t("Variants") }}</span>

                                    <div
                                        v-if="!Object.keys(product.variant_filter).length"
                                        class="flex h-20 w-full items-center justify-between rounded-lg bg-lightGray-400 p-2 sm:pl-6"
                                        @click="(customProduct = product), (selected = {})"
                                    >
                                        <div class="flex">
                                            <div class="flex h-6 items-center">
                                                <input
                                                    v-model="customProduct"
                                                    :value="product"
                                                    aria-describedby="comments-description"
                                                    class="form-radio size-5 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                    name="comments"
                                                    type="radio"
                                                >
                                            </div>
                                            <div class="ml-3 text-sm leading-6 first-letter:capitalize">
                                                <label>{{
                                                    product.variant_label ? product.variant_label : product.title
                                                }}</label>

                                                <template
                                                    v-if="
                                                        shoppingCart.getVariantPriceAddition(
                                                            selectedProduct,
                                                            product
                                                        ) !== 0
                                                    "
                                                >
                                                    {{
                                                        " ( " +
                                                            (shoppingCart.getVariantPriceAddition(
                                                                selectedProduct,
                                                                product
                                                            ) >= 0
                                                                ? "+ "
                                                                : "- ") +
                                                            $n(
                                                                Math.abs(
                                                                    shoppingCart.getVariantPriceAddition(
                                                                        selectedProduct,
                                                                        product
                                                                    )
                                                                ) / 100,
                                                                "currency",
                                                                "nl"
                                                            ) +
                                                            " )"
                                                    }}
                                                </template>
                                            </div>
                                        </div>
                                        <img
                                            alt="Product image"
                                            :src="product.first_media?.src ?? Placeholder"
                                            class="aspect-square h-full rounded-lg object-cover"
                                        >
                                    </div>

                                    <template v-for="(variant, key) in product.children">
                                        <div
                                            v-if="!Object.keys(variant.variant_filter).length"
                                            class="flex h-20 w-full items-center justify-between rounded-lg bg-lightGray-400 p-2 sm:pl-6"
                                            @click="(customProduct = variant), (selected = {})"
                                        >
                                            <div class="flex grow items-center">
                                                <div class="flex h-6 items-center">
                                                    <input
                                                        v-model="customProduct"
                                                        :value="variant"
                                                        aria-describedby="comments-description"
                                                        class="form-radio size-5 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                        name="comments"
                                                        type="radio"
                                                    >
                                                </div>
                                                <div class="ml-3 text-sm leading-6 first-letter:capitalize">
                                                    <div
                                                        class="flex flex-col font-medium text-gray-900 sm:flex-row sm:gap-1"
                                                    >
                                                        <div>
                                                            {{ variant.variant_label }}
                                                        </div>

                                                        <template
                                                            v-if="
                                                                shoppingCart.getVariantPriceAddition(
                                                                    selectedProduct,
                                                                    variant
                                                                ) !== 0
                                                            "
                                                        >
                                                            <div>
                                                                {{
                                                                    " ( " +
                                                                        (shoppingCart.getVariantPriceAddition(
                                                                            selectedProduct,
                                                                            variant
                                                                        ) >= 0
                                                                            ? "+ "
                                                                            : "- ") +
                                                                        $n(
                                                                            Math.abs(
                                                                                shoppingCart.getVariantPriceAddition(
                                                                                    selectedProduct,
                                                                                    variant
                                                                                )
                                                                            ) / 100,
                                                                            "currency",
                                                                            "nl"
                                                                        ) +
                                                                        " )"
                                                                }}
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                alt="Product image"
                                                :src="
                                                    variant.first_media?.src ?? product.first_media?.src ?? Placeholder
                                                "
                                                class="aspect-square h-full rounded-lg object-cover"
                                            >
                                        </div>
                                    </template>
                                </template>
                                <template v-if="product.extras?.length">
                                    <div class="mt-2 text-xl font-bold uppercase">
                                        {{ $t("Extra's") }}
                                    </div>

                                    <div
                                        v-for="(extra, key) in product.extras"
                                        class="flex h-20 w-full items-center justify-between rounded-lg bg-lightGray-400 p-2 sm:pl-6"
                                    >
                                        <div
                                            class="flex grow items-center"
                                            @click="extra.is_selected = !extra.is_selected"
                                        >
                                            <div class="flex h-6 items-center">
                                                <input
                                                    v-model="extra.is_selected"
                                                    aria-describedby="comments-description"
                                                    class="form-checkbox size-5 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                                                    name="comments"
                                                    type="checkbox"
                                                >
                                            </div>
                                            <div class="ml-3 text-sm leading-6">
                                                <div
                                                    class="flex flex-col font-medium text-gray-900 sm:flex-row sm:gap-1"
                                                >
                                                    <div>
                                                        <span>{{ extra.title }}</span>
                                                    </div>
                                                    <div>
                                                        <span>{{
                                                            " ( + " +
                                                                $n(
                                                                    shoppingCart.calculatePayableProductPrice(extra) /
                                                                        100,
                                                                    "currency",
                                                                    "nl"
                                                                ) +
                                                                " )"
                                                        }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="!extra.is_deposit && extra.is_selected"
                                            class="flex justify-end pr-2 sm:pr-4"
                                        >
                                            <NumberInput
                                                v-model="extra.selected_amount"
                                                variant="dense"
                                                :min="extra.min_order"
                                                :max="extra.max_order"
                                                size="responsive"
                                            />
                                        </div>
                                        <img
                                            alt="Product image"
                                            :src="extra.first_media?.src ?? Placeholder"
                                            class="aspect-square h-full rounded-lg object-cover"
                                        >
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div
                            class="flex w-full flex-wrap items-center justify-between bg-lightGray-400 px-4 py-3 shadow-[10px_10px_10px_10px_rgba(0,0,0,0.25)] sm:rounded-b-xl sm:px-6 sm:shadow-none"
                        >
                            <div>
                                <NumberInput
                                    v-model="amount"
                                    :min="product.min_order"
                                    :max="product.max_order"
                                    size="responsive"
                                />
                            </div>

                            <div class="order-first mb-4 flex basis-full sm:order-none sm:mb-0 sm:basis-auto">
                                <Price
                                    :discount_price="payablePrice.discount"
                                    :price="payablePrice.original"
                                    class="mx-auto"
                                />
                            </div>
                            <div>
                                <Button
                                    :disabled="!productId"
                                    size="responsive"
                                    @click.prevent="closeConfigurator()"
                                >
                                    {{ $t("Order now") }}
                                </Button>
                            </div>
                        </div>
                    </DialogPanel>

                    <DialogPanel
                        v-else
                        class="relative flex w-full flex-col overflow-hidden bg-white text-left shadow-xl transition-all sm:max-h-[80vh] sm:max-w-screen-md sm:rounded-xl"
                    />
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
