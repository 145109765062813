<script setup>
import {ArrowLongLeftIcon, ArrowLongRightIcon} from '@heroicons/vue/20/solid';
import {Link} from '@inertiajs/vue3';
import {computed} from 'vue';

const props = defineProps({
    data: Object,
});

const links = computed(() => {
    return props.data.links.slice(1, -1);
});

</script>

<template>
    <nav
        v-if="data.links.length > 3"
        class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
    >
        <div class="-mt-px flex w-0 flex-1">
            <Link
                v-if="data.prev_page_url"
                :href="data.prev_page_url"
                class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-primary-400 hover:text-primary-400"
            >
                <ArrowLongLeftIcon
                    aria-hidden="true"
                    class="mr-3 size-5 text-gray-400"
                />
                {{ $t('Previous') }}
            </Link>
        </div>
        <div class="hidden md:-mt-px md:flex">
            <Link
                v-for="link in links"
                :class="link.label === data.current_page.toString() ? 'border-primary-400 text-primary-400' : link.label === '...' ? 'border-transparent text-gray-500' : 'border-transparent text-gray-500 hover:border-primary-400 hover:text-primary-400' "
                :href="link.url"
                class="inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium"
            >
                {{ link.label }}
            </Link>
        </div>

        <div class="-mt-px flex w-0 flex-1 justify-end">
            <Link
                v-if="data.next_page_url"
                :href="data.next_page_url"
                class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-primary-400 hover:text-primary-400"
            >
                {{ $t('Next') }}
                <ArrowLongRightIcon
                    aria-hidden="true"
                    class="ml-3 size-5 text-gray-400"
                />
            </Link>
        </div>
    </nav>
</template>
