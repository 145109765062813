<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    show: Boolean,
    size: {
        type: String,
        default: 'default',
        validator(value) {
            // The value must match one of these strings
            return ['default', 'big'].includes(value);
        },
    },
});
</script>

<template>
    <TransitionRoot
        :show="show"
        as="template"
    >
        <Dialog
            as="div"
            class="relative z-40"
            @close="$emit('close')"
        >
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>

            <div
                :class="['big'].includes(size) ? 'sm:max-w-140' : 'sm:max-w-120'"
                class="fixed inset-y-0 right-0 z-40 flex w-full max-w-screen-sm"
            >
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="translate-x-full"
                    enter-to="-translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="-translate-x-0"
                    leave-to="translate-x-full"
                >
                    <DialogPanel
                        class="relative flex w-full flex-1 flex-col rounded-l-3xl bg-[#f5f2ef] px-4 pb-4 pt-5 sm:px-8"
                    >
                        <slot />
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
