<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { computed, inject } from 'vue';

import Breadcrumb from '@/Components/Breadcrumb.vue';
import TextHeader from '@/Components/Partitials/TextHeader.vue';

const props = defineProps({
    page: Object,
});

const route = inject('route');

const PageContent = computed(() => {
    return props.page.blocks.find((element) => element.component_name === 'content');
});
</script>

<template>
    <PublicLayout
        :description="page?.seo_description"
        :title="page?.seo_title"
    >
        <Breadcrumb
            :pages="[{ name: $t('Supply and Delivery'), href: route('public.supply-and-delivery'), current: true }]"
        />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <TextHeader
                :title="$t('Supply and Delivery')"
                class="mx-auto mb-12 w-4/5"
            />

            <!--                <div class="md:order-last">-->
            <!--                    <img :src="About" alt="Over ons" class="w-full" />-->
            <!--                </div>-->

            <div>
                <div
                    class="prose prose-lg max-w-none"
                    v-html="PageContent.content.text"
                />

                <!--                    <div class="mt-4">-->
                <!--                        <Button routeName="public.contact" size="large">{{ $t('Contact') }}</Button>-->
                <!--                    </div>-->
            </div>
        </div>
    </PublicLayout>
</template>
