<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';

import Placeholder from '@/assets/images/product-placeholder.webp';
import Breadcrumb from '@/Components/Breadcrumb.vue';
import Button from '@/Components/Inputs/Button.vue';
import Paginator from '@/Components/Paginator.vue';
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    page: Object,
    categories: Object,
    query: Object,
});
</script>

<template>
    <PublicLayout
        :description="page?.seo_description"
        :title="page?.seo_title"
    >
        <Breadcrumb
            :pages="[{ name: $t('Categories'), href: route('public.categories.index'), current: false }]"
        />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <div class="grid grid-cols-2 gap-4 py-10 sm:gap-8 sm:py-6 lg:grid-cols-4">
                <Button
                    v-for="i in categories.data"
                    :route-params="{ category: i.page.slug }"
                    class="flex w-full flex-col justify-center"
                    disable-style
                    routeName="public.categories.show"
                >
                    <div
                        class="group relative flex aspect-square w-full justify-center overflow-hidden rounded-3xl border-8 border-white bg-white sm:rounded-xl"
                    >
                        <img
                            :src="i.first_media?.src ?? Placeholder"
                            :srcset="i.first_media?.src_set"
                            alt="Image"
                            class="size-full object-cover transition-all group-hover:scale-105"
                            sizes="40vw"
                        >

                        <div
                            class="absolute bottom-3 hidden max-w-[90%] truncate rounded-full bg-white px-4 py-1 text-center font-bold sm:block"
                        >
                            <span class="truncate uppercase">{{ i.title }}</span>
                        </div>
                    </div>

                    <div
                        class="mt-3 flex items-center justify-center rounded-full bg-primary-400 p-2 text-sm font-bold sm:hidden"
                    >
                        <span class="truncate uppercase">{{ i.title }}</span>
                    </div>
                </Button>
            </div>

            <div class="relative mx-auto max-w-7xl px-4 pb-20 sm:px-6 lg:px-8 lg:pb-20">
                <div class="relative mx-auto max-w-7xl">
                    <Paginator :data="categories" />
                </div>
            </div>
        </div>
    </PublicLayout>
</template>
