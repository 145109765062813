<script setup>
import {Link} from '@inertiajs/vue3';
import {computed, inject} from 'vue';

const router = inject('route');

const props = defineProps({
    color: String,
    fullWidth: Boolean,
    squared: Boolean,
    href: String,
    routeName: String,
    routeParams: Object,
    nonUppercase: Boolean,
    disabled: Boolean,
    disableStyle: Boolean,
    noPadding: Boolean,
    noInertia: Boolean,
    size: {
        type: String,
        default: 'default',
        validator(value) {
            // The value must match one of these strings
            return ['default', 'small', 'large', 'responsive'].includes(value);
        },
    },
});

const emit = defineEmits(['click']);

const color = computed(() => {
    switch (props.color) {
        case 'primary':
            return 'bg-primary-400';
        case 'secondary':
            return 'bg-secondary-400';
        case 'gray':
            return 'bg-gray-700';
        case 'error':
            return 'bg-red-600';
        default:
            return 'bg-primary-400';
    }
});
</script>

<template>
    <component
        :is="noInertia ? 'a' : href || routeName ? Link : 'button'"
        :class="[
            !disableStyle ? color : '',
            disabled ? 'cursor-default opacity-50' : !disableStyle ? 'hover:bg-primary-300' : '',
            fullWidth ? 'w-full' : '',
            squared ? 'rounded-lg' : !disableStyle ? 'rounded-full' : '',
            nonUppercase ? '' : !disableStyle ? 'uppercase' : '',
            noPadding ? '!px-2' : !disableStyle ? '' : '',
            !disableStyle ? 'truncate border border-transparent text-center font-bold text-black shadow-sm' : '',
            ['small'].includes(size) ? 'px-6 py-1 text-base' : '',
            ['large'].includes(size) ? 'px-22 py-1.5 text-base' : '',
            ['default'].includes(size) && !disableStyle ? 'px-10 py-1.5 text-base' : '',
            ['responsive'].includes(size) ? 'px-6 py-1 sm:px-10 sm:py-1.5' : '',
        ]"
        :href="routeName ? router(routeName, routeParams) : href"
        @click="disabled ? $event.preventDefault() : emit('click', $event)"
    >
        <slot />
    </component>
</template>
