<script setup>
import { mapErrors } from '@/utilities';
import Button from '@/Components/Inputs/Button.vue';
import TextHeader from '@/Components/Partitials/TextHeader.vue';

import { computed, inject, ref } from 'vue';
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { useForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import { notify } from 'notiwind';
import Breadcrumb from '@/Components/Breadcrumb.vue';

const props = defineProps({
    page: Object,
    company_details: Object,
});
const submittedForm = ref(false);

const { t } = useI18n();
const route = inject('route');
const sendForm = (data) => {
    const form = useForm(data);

    form.post(route('api.contact-form'), {
        preserveState: true,
        preserveScroll: true,
        wantsJson: true,
        onSuccess: () => {
            submittedForm.value = true;

            notify(
                {
                    type: 'success',
                    title: t('Message sent'),
                    text: t("You'll hear from us as soon as possible"),
                },
                4000
            );
        },
        onError: (errors) => {
            form.errors = mapErrors(errors, (error) => [error]);
        },
    });
};

const PageContent = computed(() => {
    return props.page.blocks.find((element) => element.component_name === 'content');
});
</script>

<template>
    <PublicLayout
        :description="page?.seo_description"
        :title="page?.seo_title"
    >
        <Breadcrumb :pages="[{ name: $t('Contact'), href: route('public.contact'), current: true }]" />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <TextHeader
                class="mx-auto mb-12 w-4/5"
                title="Contact"
            />

            <div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
                <div
                    class="prose prose-lg"
                    v-html="PageContent.content.text"
                />

                <FormKit
                    :actions="false"
                    submit-label="Register"
                    type="form"
                    @submit="sendForm"
                >
                    <div class="flex flex-col gap-5">
                        <div class="grid grid-cols-6 gap-4 rounded-xl bg-white p-5">
                            <div class="col-span-full">
                                <FormKit
                                    :label="$t('Name')"
                                    autocomplete="given-name"
                                    name="name"
                                    type="text"
                                    validation="required"
                                />
                            </div>

                            <div class="col-span-full">
                                <FormKit
                                    :label="$t('Email')"
                                    autocomplete="email"
                                    name="email"
                                    type="email"
                                    validation="required"
                                />
                            </div>
                            <div class="col-span-full">
                                <FormKit
                                    :label="$t('Message')"
                                    name="message"
                                    type="textarea"
                                    validation="required"
                                />
                            </div>
                            <div class="col-span-full flex justify-center">
                                <Button
                                    class="col-span-2"
                                    size="large"
                                >
                                    {{ $t('Send') }}
                                </Button>
                            </div>
                        </div>
                    </div>
                </FormKit>
            </div>
        </div>
    </PublicLayout>
</template>
