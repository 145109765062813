<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';

import { inject } from 'vue';

import ProductGrid from '@/Components/Product/ProductGrid.vue';
import { useCartStore } from '@/stores/cart';
import Paginator from '@/Components/Paginator.vue';
import GeneralHero from '@/Components/GeneralHero.vue';
import Breadcrumb from '@/Components/Breadcrumb.vue';
import Button from '@/Components/Inputs/Button.vue';
import { usePage } from '@inertiajs/vue3';

const route = inject('route');

const props = defineProps({
    category: Object,
    products: Object,
    query: Object,
});

const shoppingCart = useCartStore();

const auth = usePage().props.auth;
</script>

<template>
    <PublicLayout
        :description="category.page?.seo_description"
        :image="category.og_image"
        :title="category.page?.seo_title"
    >
        <GeneralHero
            v-if="category.title"
            :categories="[category]"
        />
        <Breadcrumb
            :pages="[
                { name: $t('Categories'), href: route('public.categories.index'), current: false },

                category.title
                    ? {
                        name: category.title,
                        href: route('public.categories.show', { category: category.page.slug }),
                        current: false,
                    }
                    : { name: 'Aanbiedingen', href: route('public.categories.discounts.show'), current: true },
            ]"
        />

        <div
            class="prose prose-xl mx-auto max-w-7xl px-4 pb-8 pt-10 text-dark sm:px-6 sm:pt-24"
            v-html="category.description"
        />

        <div class="mx-auto max-w-7xl px-4 pb-8 sm:px-6">
            <template v-if="products.data.length">
                <ProductGrid
                    :products="products.data"
                    type="category"
                />
            </template>

            <div
                v-else
                class="mt-20"
            >
                <div class="text-4xl font-bold">
                    <span>{{ $t('No products found') }}</span>
                </div>
            </div>

            <div class="relative mx-auto max-w-7xl px-4 pb-20 sm:px-6 lg:px-8 lg:pb-20">
                <div class="relative mx-auto max-w-7xl">
                    <Paginator :data="products" />
                </div>
            </div>
        </div>

        <div
            v-if="auth?.administrator"
            class="fixed bottom-15 left-15"
        >
            <Button
                no-inertia
                class="mt-2"
                :href="'/admin/categories/' + category.id + '/edit'"
            >
                Bewerk categorie
            </Button>
        </div>
    </PublicLayout>
</template>
