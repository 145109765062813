<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';

import { inject, ref } from 'vue';
import ProductGrid from '@/Components/Product/ProductGrid.vue';
import Breadcrumb from '@/Components/Breadcrumb.vue';
import ProductFilter from '@/Components/ProductFilter.vue';
import Paginator from '@/Components/Paginator.vue';

const route = inject('route');

const props = defineProps({
    page: Object,
    products: Object,
    categories: Object,
    query: Object,
});

props.query.sort = 'default';
const processing = ref(false);
</script>

<template>
    <PublicLayout
        :description="page?.seo_description"
        :title="page?.seo_title"
    >
        <Breadcrumb
            :pages="[{ name: $t('Products'), href: route('public.products.index'), current: true }]"
        />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-4">
                <div class="self-start lg:sticky lg:top-40">
                    <ProductFilter
                        v-model:processing="processing"
                        :categories="categories"
                        :query="query"
                        route-name="public.products.index"
                    />
                </div>

                <div class="flex flex-col gap-10 lg:col-span-3">
                    <div class="hidden items-center justify-between sm:flex">
                        <div>
                            <span>{{ $t('Amount of products:') }} <b>{{ products.data.length }}</b></span>
                        </div>
                        <div class="flex items-center">
                            <span>{{ $t('Sory by:') }}</span>
                            <div>
                                <select
                                    id="location"
                                    v-model="query.sort"
                                    class="form-select ml-2 block w-full rounded-md border-0 bg-transparent py-0 pr-8 font-bold text-gray-900"
                                    name="location"
                                >
                                    <option
                                        selected
                                        value="default"
                                    >
                                        {{ $t('Default') }}
                                    </option>
                                    <option value="price">
                                        {{ $t('Price: Low - High') }}
                                    </option>
                                    <option value="price-desc">
                                        {{ $t('Price: High - Low') }}
                                    </option>

                                    <option value="sales">
                                        {{ $t('Most sold') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <template v-if="products.data.length">
                        <ProductGrid
                            :products="products.data"
                            type="category"
                        />
                    </template>

                    <div
                        v-else
                        class="mt-20"
                    >
                        <div class="text-4xl font-bold">
                            <span> {{ $t('No products found') }} </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative mx-auto max-w-7xl px-4 pb-20 sm:px-6 lg:px-8 lg:pb-20">
                <div class="relative mx-auto max-w-7xl">
                    <Paginator :data="products" />
                </div>
            </div>
        </div>
    </PublicLayout>
</template>
