<script setup>
import Placeholder from '@/assets/images/product-placeholder.webp';
import Button from '@/Components/Inputs/Button.vue';
import { inject } from 'vue';

const route = inject('route');
const props = defineProps({
    categories: Object,
});
</script>

<template>
    <div
        v-if="categories.length === 1"
        class="w-full"
    >
        <div class="relative h-60 overflow-hidden sm:h-100">
            <img
                alt="Hero image"
                :src="categories[0].first_hero_media?.src ?? categories[0].first_media?.src ?? Placeholder"
                :srcset="categories[0].first_hero_media?.src_set ?? categories[0].first_media?.src_set"
                class="absolute -z-10 size-full object-cover"
            >

            <div class="mx-auto h-full max-w-7xl px-4 sm:px-6">
                <div class="flex h-full items-center justify-center space-x-8 py-6">
                    <h1 class="mb-3 text-4xl font-bold uppercase text-white sm:text-6xl md:text-8xl">
                        {{ categories[0].title }}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div
        v-else
        class="w-full"
    >
        <div class="bg-lightGray-400">
            <div class="mx-auto max-w-7xl px-4 sm:px-6">
                <div class="grid grid-cols-2 gap-4 py-10 sm:gap-8 sm:py-6 lg:flex lg:grid-cols-4">
                    <Button
                        v-for="i in categories.filter((category) => !category.hide_on_header).slice(0, 4)"
                        :route-params="{ category: i.page.slug }"
                        class="flex w-full flex-col justify-center"
                        disable-style
                        routeName="public.categories.show"
                    >
                        <div
                            class="group relative flex aspect-square max-h-72 w-full justify-center overflow-hidden rounded-3xl border-8 border-white bg-white sm:rounded-xl"
                        >
                            <img
                                alt="Hero image"
                                :src="i.first_media?.src ?? Placeholder"
                                :srcset="i.first_media?.src_set"
                                class="size-full object-cover transition-all group-hover:scale-105"
                                sizes="40vw"
                            >

                            <div
                                class="absolute bottom-3 hidden max-w-[90%] truncate rounded-full bg-white px-4 py-1 text-center font-bold sm:block"
                            >
                                <span class="truncate uppercase">{{ i.title }}</span>
                            </div>
                        </div>

                        <div
                            class="mt-3 flex items-center justify-center rounded-full bg-primary-400 p-2 text-sm font-bold sm:hidden"
                        >
                            <span class="truncate uppercase">{{ i.title }}</span>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
