import axios from 'axios';
import {inject} from 'vue';

export function useCommerceHelpers() {
    const route = inject('route') as Function;

    async function validateAddress(postal_code: string, house_number: string, with_delivery: boolean = false) {
        try {
            const response = await axios.post(route('api.commerce.address'), {
                postal_code: postal_code,
                house_number: house_number,
            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    return {validateAddress};
}
