<script setup>
import {Head, usePage} from '@inertiajs/vue3';

import Header from '@/Components/Layout/Header.vue';
import Footer from '@/Components/Layout/Footer.vue';
import ProductConfigurator from '@/Components/Modals/ProductConfigurator.vue';
import {inject} from 'vue';
import {useCartStore} from '@/stores/cart';
import {Notification, NotificationGroup} from 'notiwind';

const props = defineProps({
    title: String,
    description: String,
    image: String,
    pageType: String,
});

const route = usePage().props.route;
if (typeof axios !== 'undefined') axios.defaults.headers.common['Accept-Language'] = usePage().props.locale;

const shoppingCart = useCartStore();
shoppingCart.load(usePage().props.shopping_cart, inject('route'));

const noIndex = import.meta.env.VITE_NO_INDEX === 'true';
</script>

<template>
    <Head>
        <title>{{ title }}</title>
        <meta
            :content="description"
            head-key="description"
            name="description"
        >
        <meta
            content="De Vers Specialist"
            property="og:site_name"
        >
        <meta
            :content="title"
            property="og:title"
        >
        <meta
            :content="description"
            property="og:description"
        >
        <meta
            :content="image"
            property="og:image"
        >
        <meta
            :content="pageType ?? 'website'"
            property="og:type"
        >
        <meta
            :content="route.current"
            property="og:url"
        >
        <meta
            :content="$i18n.locale"
            property="og:locale"
        >

        <link
            :href="route.locale.nl"
            hreflang="nl"
            rel="alternate"
        >
        <link
            :href="route.locale.nl"
            hreflang="x-default"
            rel="alternate"
        >
        <link
            :href="route.current"
            rel="canonical"
        >
    </Head>

    <!--    TODO Vue 3.3 doesnt like this in Inertia head -->
    <!--    <meta v-if="noIndex" name="robots" content="noindex" />-->
    <!--    <meta v-if="route.locale.en" :content="$i18n.locale === 'en' ? 'nl' : 'en'" property="og:locale:alternate" />-->
    <!--    <link v-if="route.locale.en" :href="route.locale.en" hreflang="en" rel="alternate" />-->

    <Header />

    <main class="mb-20">
        <slot />
    </main>

    <Footer />

    <ProductConfigurator v-if="shoppingCart.productConfigurator" />

    <NotificationGroup>
        <div class="pointer-events-none fixed inset-0 z-50 flex items-start justify-end p-6 px-4">
            <div class="w-full max-w-sm">
                <Notification
                    v-slot="{ notifications }"
                    enter="transform ease-out duration-300 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    move="transition duration-500"
                    move-delay="delay-300"
                >
                    <div
                        v-for="notification in notifications"
                        :key="notification.id"
                    >
                        <div
                            v-if="notification.type === 'success'"
                            class="mx-auto mt-4 flex w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-md"
                        >
                            <div class="flex w-12 items-center justify-center bg-green-500">
                                <svg
                                    class="size-6 fill-current text-white"
                                    viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                                    />
                                </svg>
                            </div>

                            <div class="-mx-3 px-4 py-2">
                                <div class="mx-3">
                                    <span class="font-semibold text-green-500">{{ notification.title }}</span>
                                    <p class="text-sm text-gray-600">
                                        {{ notification.text }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-else-if="notification.type === 'warning'"
                            class="mx-auto mt-4 flex w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-md"
                        >
                            <div class="flex w-12 items-center justify-center bg-yellow-500">
                                <svg
                                    class="size-6 fill-current text-white"
                                    viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
                                    />
                                </svg>
                            </div>

                            <div class="-mx-3 px-4 py-2">
                                <div class="mx-3">
                                    <span class="font-semibold text-yellow-500">{{ notification.title }}</span>
                                    <p class="text-sm text-gray-600">
                                        {{ notification.text }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-else
                            class="mx-auto mt-4 flex w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-md"
                        >
                            <div class="flex w-12 items-center justify-center bg-blue-500">
                                <svg
                                    class="size-6 fill-current text-white"
                                    viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
                                    />
                                </svg>
                            </div>

                            <div class="-mx-3 px-4 py-2">
                                <div class="mx-3">
                                    <span class="font-semibold text-blue-500">{{ notification.title }}</span>
                                    <p class="text-sm text-gray-600">
                                        {{ notification.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Notification>
            </div>
        </div>
    </NotificationGroup>
</template>

<style>
body {
    background-color: #f5f2ef;
}
</style>
