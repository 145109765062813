<script setup>
import ProductImage from "@/Components/Product/ProductImage.vue";

import Button from "@/Components/Inputs/Button.vue";
import Price from "@/Components/Product/Price.vue";
import { useCartStore } from "@/stores/cart";
import { computed, inject } from "vue";

const route = inject("route");

const props = defineProps({
    product: Object,
    type: {
        type: String,
        default: "default",
        validator(value) {
            // The value must match one of these strings
            return ["default", "toppers", "discount", "related", "category", "page_block"].includes(value);
        },
    },
});

const shoppingCart = useCartStore();

const productType = computed(() => {
    if (props.product.is_discount_active) {
        return "toppers";
    }
    if (props.product.is_discount_active) {
        return "discount";
    }
    return "default";
});

const productCategories = computed(() => {
    return props.product.schema_data.map((category) => category.id);
});
const productUncombinable = computed(() => {
    return props.product.schema_data
        .map((productCategories) => productCategories.uncombinable.map((uncombinable) => uncombinable.id))
        .flat(2);
});
// let isUncombinable = computed(() => {
//     return productCategories.value.some((element) => shoppingCart.uncombinableCategories.map((uncombinable) => uncombinable.id).includes(element));
// });

// let isUncombinable2 = computed(() => {
//     return productUncombinable.value.some((element) => shoppingCart.products.map((category) => category.schema_data.map((category) => category.id)).flat(2).includes(element));
// });
const uncombinableProducts = computed(() => {
    return shoppingCart.products.filter((product) => {
        if (
            product.schema_data
                .map((productCategories) => productCategories.uncombinable.map((uncombinable) => uncombinable.id))
                .flat(2)
                .some((element) => productCategories.value.includes(element))
        ) {
            return true;
        } else if (
            product.schema_data
                .map((category) => category.id)
                .some((element) => productUncombinable.value.includes(element))
        ) {
            return true;
        }

        return false;
    });
});
</script>

<template>
    <div
        :class="[
            ['default', 'category'].includes(type === 'default' ? productType : type) ? 'bg-lightGray-400' : 'bg-white',
            ['page_block'].includes(type) ? '!bg-lightGray-400' : 'sm:flex-col sm:rounded-2xl',
        ]"
        class="flex w-full rounded-3xl"
    >
        <Button
            :route-params="{ product: product.page.slug }"
            class="m-2 w-full max-w-35"
            :class="['page_block'].includes(type) ? '' : 'sm:w-auto sm:max-w-none'"
            disable-style
            routeName="public.products.show"
        >
            <span class="sr-only">Product</span>
            <ProductImage
                :media="product.first_media"
                :type="type === 'default' ? productType : type"
                thumbnail
            />
        </Button>

        <div
            class="flex grow flex-col gap-1 p-2"
            :class="['page_block'].includes(type) ? '' : 'sm:gap-4 sm:pt-0'"
        >
            <Button
                :route-params="{ product: product.page.slug }"
                class="px-1 text-base font-bold sm:grow"
                disable-style
                routeName="public.products.show"
            >
                {{ product.title }}
            </Button>

            <Button
                :route-params="{ product: product.page.slug }"
                class="flex grow flex-col justify-center sm:grow-0"
                :class="['page_block'].includes(type) ? '' : 'sm:items-center'"
                disable-style
                routeName="public.products.show"
            >
                <span
                    v-if="!product.min_order"
                    class="text-xs sm:text-base"
                >{{
                    $t(Object.keys(product.properties)[0])
                }}</span>
                <span
                    v-else
                    class="text-xs sm:text-base"
                >Vanaf: {{ product.min_order }}</span>
                <Price
                    :discount_price="shoppingCart.getProductDiscount(product)"
                    :price="(product.min_order ?? 1) * product.price_including_vat"
                    size="small"
                />
            </Button>

            <div class="">
                <Button
                    :disabled="!!uncombinableProducts.length"
                    class="uppercase"
                    full-width
                    @click="shoppingCart.interactWithProduct(product)"
                >
                    {{ $t("Order now") }}
                </Button>

                <div
                    v-if="uncombinableProducts.length"
                    class="mt-3"
                >
                    <p class="text-sm">
                        {{ $t("Not available with product(s):") }}
                    </p>

                    <div class="my-3 flex flex-wrap gap-2">
                        <span
                            v-for="product in uncombinableProducts"
                            class="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
                        >
                            {{ product.title }}
                            <Button
                                class="ml-0.5 inline-flex size-4 shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                disable-style
                                type="button"
                                @click="shoppingCart.deleteProduct(product.id)"
                            >
                                <span class="sr-only">{{ $t("Delete product") }}</span>
                                <svg
                                    class="size-2"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 8 8"
                                >
                                    <path
                                        d="M1 1l6 6m0-6L1 7"
                                        stroke-linecap="round"
                                        stroke-width="1.5"
                                    />
                                </svg>
                            </Button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
