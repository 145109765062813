<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { inject } from 'vue';
import PageBlockList from '@/Components/PageBlockList.vue';
import Breadcrumb from '@/Components/Breadcrumb.vue';

const props = defineProps({
    page: Object,
});

const route = inject('route');
</script>

<template>
    <PublicLayout
        :description="page?.seo_description"
        :title="page?.seo_title"
    >
        <Breadcrumb :pages="[{ name: page?.title, current: true }]" />
        <PageBlockList :block-list="page.content_blocks" />
    </PublicLayout>
</template>
