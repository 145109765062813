<script setup>
import Button from '@/Components/Inputs/Button.vue';
import {PlusIcon, XMarkIcon} from '@heroicons/vue/20/solid';
import NumberInput from '@/Components/Inputs/NumberInput.vue';
import {useCartStore} from '@/stores/cart';
import Price from '@/Components/Product/Price.vue';
import Placeholder from '@/assets/images/product-placeholder.webp';
import {ref} from 'vue';
import {notify} from 'notiwind';
import {useI18n} from 'vue-i18n';

const props = defineProps({
    type: {
        type: String,
        default: 'default',
        validator(value) {
            // The value must match one of these strings
            return ['default', 'toppers', 'discount'].includes(value);
        },
    },
    editableRemarks: Boolean,
});
const {t} = useI18n();

function notifyRemarkAdded() {
    notify({type: 'success', title: t('Remark updated'), text: t('Remark is successfully saved')}, 4000);
}

const shoppingCart = useCartStore();

const noteOpen = ref();
</script>

<template>
    <div class="flex flex-col gap-5 text-left">
        <template v-for="(line, key) in shoppingCart.lines">
            <div
                :class="[!line.product.is_discount_active ? 'bg-lightGray-400' : 'bg-white']"
                class="flex rounded-3xl sm:rounded-xl"
            >
                <div class="relative m-2 flex aspect-square h-full max-h-45 justify-center">
                    <div class="w-full overflow-hidden rounded-3xl sm:rounded-xl">
                        <img
                            :src="line.product.first_media?.src ?? line.product.parent?.first_media?.src ?? Placeholder"
                            alt="Cart item"
                            class="size-full object-cover"
                        >
                    </div>
                </div>

                <div class="flex w-full flex-col gap-4 p-2">
                    <div>
                        <p class="px-1 text-sm font-bold">
                            {{ line.product.title }}
                        </p>
                        <p
                            v-if="line.product.variant_label"
                            class="px-1 text-xs font-bold text-lightGray-600"
                        >
                            {{ line.product.variant_label }}
                        </p>

                        <div class="flex items-end justify-between">
                            <div>
                                <p class="px-1 text-sm font-bold">
                                    {{ $t(Object.keys(line.product.properties)[0]) }}
                                </p>
                                <p class="px-1 text-sm">
                                    <Price
                                        :discount_price="shoppingCart.getProductDiscount(line.product)"
                                        :price="line.product.price_including_vat"
                                        hide-discount
                                        size="small"
                                    />
                                </p>
                            </div>
                            <div class="flex items-center gap-2">
                                <span class="text-xl font-bold"><Price
                                    :discount_price="shoppingCart.getProductDiscount(line.product) * line.quantity"
                                    :price="line.product.price_including_vat * line.quantity"
                                    hide-discount
                                    size="small"
                                /></span>
                                <button @click="shoppingCart.deleteProduct(line.product.id)">
                                    <x-mark-icon class="size-7" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <NumberInput
                        v-model="line.quantity"
                        allow-zero
                        size="responsive"
                        @update:model-value="shoppingCart.setQuantity(line, $event)"
                    />
                </div>
            </div>

            <template v-if="editableRemarks">
                <Button
                    class="flex items-center"
                    disable-style
                    @click="noteOpen === key ? (noteOpen = null) : (noteOpen = key)"
                >
                    <PlusIcon class="size-5" />
                    {{ $('Add Remark') }}
                </Button>
                <div
                    v-show="noteOpen === key || line.remark"
                    class="w-full p-1"
                >
                    <FormKit
                        v-model="line.remark"
                        :delay="1000"
                        :label="$t('Remark')"
                        type="textarea"
                        @input="shoppingCart.setRemark(line, $event).then(notifyRemarkAdded)"
                    />
                </div>
            </template>
        </template>
    </div>
</template>

<style scoped></style>
