<script lang="js" setup>
import Breadcrumb from "@/Components/Breadcrumb.vue";
import CartItems from "@/Components/CartItems.vue";
import Button from "@/Components/Inputs/Button.vue";
import TextHeader from "@/Components/Partitials/TextHeader.vue";
import PublicLayout from "@/Layouts/PublicLayout.vue";
import EmptyDot from "@/assets/images/empty-dot.svg";
import FilledDot from "@/assets/images/filled-dot.svg";
import {useCommerceHelpers} from "@/composables/commerceHelpers";
import {useCartStore} from "@/stores/cart";
import {mapErrors} from "@/utilities";
import {useForm} from "@inertiajs/vue3";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {useDebounceFn, useStorage} from "@vueuse/core";
import "flatpickr/dist/flatpickr.css";
import {notify} from "notiwind";
import {computed, inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import MinPriceForDeliverySign from "@/Pages/Public/Checkout/MinPriceForDeliverySign.vue";

const dayjs = inject('dayjs');
const route = inject("route");
const {t} = useI18n();

const props = defineProps({
    ecommerceSettings: Object,
    locations: Object,
});

const checkoutDisabled = ref(false);
const availableTimes = ref([]);

function loadPickerData() {
    axios
        .get(
            route("api.commerce.load-shopping-cart-timeslots", {
                location: shoppingCart.delivery_method === "pickup" ? shoppingCart.pickup_location : null,
                delivery_method: shoppingCart.delivery_method,
                category_schema: shoppingCart.schema.id,
                month: selectedMonth.value,
                year: selectedYear.value,
            })
        )
        .then((response) => {
            availableTimes.value = response.data;
        });
}

function closePicker() {
    if (
        deliveryDate.value &&
        (new Date(deliveryDate.value).getMonth() + 1 !== selectedMonth.value ||
            new Date(deliveryDate.value).getFullYear() !== selectedYear.value)
    ) {
        resetDatePickerTimes();
        selectedMonth.value = new Date(deliveryDate.value).getMonth() + 1;
        selectedYear.value = new Date(deliveryDate.value).getFullYear();
        loadPickerData();
    }
}

function timeslotLoader(instance) {
    if (
        !instance &&
        deliveryDate.value &&
        (new Date(deliveryDate.value).getMonth() + 1 !== new Date().getMonth() + 1 ||
            new Date(deliveryDate.value).getFullYear() !== new Date().getFullYear())
    ) {
        return;
    }

    resetDatePickerTimes();
    selectedMonth.value =
        (instance?.month ??
            (deliveryDate.value ? new Date(deliveryDate.value).getMonth() : null) ??
            new Date().getMonth()) + 1;
    selectedYear.value =
        instance?.year ??
        (deliveryDate.value ? new Date(deliveryDate.value).getFullYear() : null) ??
        new Date().getFullYear();

    if (!shoppingCart.schema) {
        notify(
            {
                type: "warning",
                title: t("Product cant be sold"),
                text: t("Contact De Vers Specialist"),
            },
            4000
        );
        return;
    }

    loadPickerData();
}

const shoppingCart = useCartStore();

const formErrors = ref();
const purchaseCompleted = useStorage("purchase_completed", false);
const form = useForm({
    first_name: null,
    last_name: null,
    company_name: null,
    email: null,
    phone_number: null,
    postal_code: null,
    street_name: null,
    street_number: null,
    street_number_addition: null,
    city: null,
    invoice_postal_code: null,
    invoice_street_name: null,
    invoice_street_number: null,
    invoice_street_number_addition: null,
    invoice_city: null,
    remark: null,
    delivery_date: null,
    delivery_time: null,
    same_address: true,
    terms: false,
    newsletter: false,
});

const sendForm = () => {
    checkoutDisabled.value = true;
    // const form = useForm(data);

    purchaseCompleted.value = false;

    form.transform((data) => ({
        ...data,
        first_name: customer.value.first_name,
        last_name: customer.value.last_name,
        company_name: customer.value.company_name,
        email: customer.value.email,
        phone_number: customer.value.phone_number,

        postal_code: address.value.postal_code,
        street_name: address.value.street_name,
        street_number: address.value.street_number?.toString(),
        street_number_addition: address.value.street_number_addition,
        city: address.value.city,

        invoice_postal_code: invoiceAddress.value.postal_code,
        invoice_street_name: invoiceAddress.value.street_name,
        invoice_street_number: invoiceAddress.value.street_number?.toString(),
        invoice_street_number_addition: invoiceAddress.value.street_number_addition,
        invoice_city: invoiceAddress.value.city,

        delivery_method: shoppingCart.delivery_method,
        pickup_location: shoppingCart.delivery_method === "pickup" ? shoppingCart.pickup_location : null,
        delivery_date: deliveryTime.value?.t,
        interval_date: deliveryTime.value?.t ? dayjs(deliveryTime.value.t).add(deliveryTime.value.i, "minute") : null,
        schema_id: shoppingCart.schema.id,

        remark: remark.value,


    })).post(route("api.commerce.checkout"), {
        preserveScroll: true,
        onError: (errors) => {
            formErrors.value = errors;
            form.errors = mapErrors(errors)
            checkoutDisabled.value = false;
        },
    });
};

onMounted(() => {
    if (shoppingCart.coupon) {
        discountCode.value = shoppingCart.coupon.code;
    }

    shoppingCart.setEcommerceSettings(props.ecommerceSettings);
});

const commerceHelpers = useCommerceHelpers();


const address = ref({
    postal_code: null,
    street_name: null,
    street_number: null,
    city: null,
    street_number_addition: null,
});

const invoiceAddress = ref({
    postal_code: null,
    street_name: null,
    street_number: null,
    city: null,
    street_number_addition: null,
});

const sameAddress = ref(true);

const customer = ref({
    first_name: null,
    last_name: null,
    company_name: null,
    email: null,
    phone_number: null,
});

const remark = ref("");

const deliveryDate = ref(null);
const deliveryTime = ref(null);

const selectedMonth = ref(null);
const selectedYear = ref(null);

const debounceValidateAddress = useDebounceFn(() => {
    validateAddress();
}, 200);

const validateAddress = async () => {
    if (!address.value.postal_code || !address.value.street_number) {
        return;
    }

    const newAddress = await commerceHelpers.validateAddress(
        address.value.postal_code,
        address.value.street_number.toString(),
    );

    if (!newAddress) {
        shoppingCart.$patch({
            address: {
                distance_mijdrecht_forth: null,
                distance_uithoorn_forth: null,
            },
        });

        notify(
            {
                type: "warning",
                title: t("Address not valid"),
                text: t("Fill in another address"),
            },
            3000
        );

        return;
    }

    newAddress.postal_code = newAddress.postcode;
    address.value = newAddress;

    shoppingCart.$patch({
        address: {
            postal_code: newAddress.postcode,
            street_name: newAddress.street_name,
            street_number: newAddress.street_number,
            city: newAddress.city,
            distance_mijdrecht_forth: newAddress.distance_mijdrecht_forth ?? null,
            distance_uithoorn_forth: newAddress.distance_uithoorn_forth ?? null,
            preferred_deliverer: newAddress.preferred_deliverer,
        },
    });

    resetDatePicker();
};

const debounceUpdateMetaData = useDebounceFn(() => {
    updateMetaData();
}, 200);
const updateMetaData = async () => {
    await axios.patch(
        route("api.commerce.shopping-cart-metadata", {
            customer: customer.value,
            invoice_address: invoiceAddress.value,
            remark: remark.value,
            same_address: sameAddress.value,
        })
    );
};

function formatAddress(address) {
    return (
        address.street_name +
        " " +
        address.street_number +
        (address.addition ? " " + address.addition : "") +
        ", " +
        address.postal_code +
        " " +
        address.city
    );
}

function resetDatePicker() {
    deliveryDate.value = null;
    resetDatePickerTimes();
}

function resetDatePickerTimes() {
    deliveryTime.value = null;
    availableTimes.value = [];
}

const UnavailableLocationMessages = ref([]);

const getAvailablePickupLocations = computed(() => {
    UnavailableLocationMessages.value = [];

    return props.locations.filter((location) => {
        const products = shoppingCart.products.filter((product) => {
            const checker =
                product.locations.map((location) => location.id).includes(location.id) || !product.locations.length;

            return !checker;
        });

        if (products.length && shoppingCart.products.length > 1) {
            UnavailableLocationMessages.value = [
                ...UnavailableLocationMessages.value,
                {
                    location,
                    products,
                },
            ];
        }

        return !products.length;
    });
});

const getOutOfDeliveryRangeProducts = computed(() => {
    return shoppingCart.products.filter((product) =>
        product.schema_data.some(
            (category) =>
                category.category_schema?.max_km <
                Math.min(shoppingCart.address.distance_mijdrecht_forth, shoppingCart.address.distance_uithoorn_forth)
        )
    );
});


const discountCode = ref(null);

const invalidCode = ref(false);

function applyCoupon() {
    shoppingCart.setCoupon(discountCode.value).then(() => {
        if (shoppingCart.coupon) {
            discountCode.value = shoppingCart.coupon.code;
            invalidCode.value = false;

            notify(
                {
                    type: "success",
                    title: t("Coupon added"),
                    text: t("Coupon successfully added"),
                },
                4000
            );
        } else {
            invalidCode.value = true;

            notify(
                {
                    type: "warning",
                    title: t("Coupon invalid"),
                    text: t("Coupon is no available anymore"),
                },
                4000
            );
        }
    });
}

function deleteCoupon() {
    shoppingCart.deleteCoupon().then(() => {
        discountCode.value = null;
        notify(
            {
                type: "success",
                title: t("Coupon deleted"),
                text: t("Coupon successfully deleted"),
            },
            4000
        );
    });
}
</script>

<template>
    <PublicLayout>
        <Breadcrumb :pages="[{ name: $t('Checkout'), href: route('public.checkout'), current: true }]" />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <TextHeader
                class="mx-auto mb-12 w-4/5"
                title="Bestellen"
            />

            <div
                v-if="shoppingCart.products.length"
                class="grid grid-cols-1 gap-8 lg:grid-cols-2"
            >
                <div>
                    <CartItems />
                </div>

                <div>
                    <div class="flex flex-col gap-5">
                        <div class="grid grid-cols-6 gap-4 rounded-xl bg-white p-5">
                            <div class="col-span-3">
                                <FormKit
                                    v-model="customer.first_name"
                                    :errors="form.errors.first_name"
                                    :label="$t('First name')"
                                    autocomplete="given-name"
                                    name="first_name"
                                    type="text"

                                    @change="debounceUpdateMetaData"
                                />
                            </div>
                            <div class="col-span-3">
                                <FormKit
                                    v-model="customer.last_name"
                                    :errors="form.errors.last_name"
                                    :label="$t('Last name')"
                                    autocomplete="family-name"
                                    name="last_name"
                                    type="text"
                                    @change="debounceUpdateMetaData"
                                />
                            </div>

                            <div class="col-span-full">
                                <FormKit
                                    v-model="customer.company_name"
                                    :errors="form.errors.company_name"
                                    :label="$t('Company name')"
                                    name="company_name"
                                    type="text"
                                    @change="debounceUpdateMetaData"
                                />
                            </div>

                            <div class="col-span-full sm:col-span-2">
                                <FormKit
                                    v-model="address.postal_code"
                                    :errors="form.errors.postal_code"
                                    :label="$t('Postalcode')"
                                    autocomplete="off"
                                    name="postal_code"
                                    type="text"
                                    @blur="debounceValidateAddress()"
                                />
                            </div>
                            <div class="col-span-3 sm:col-span-2">
                                <FormKit
                                    v-model="address.street_number"
                                    :errors="form.errors.street_number"
                                    :label="$t('Street number')"
                                    autocomplete="off"
                                    name="street_number"
                                    type="number"
                                    @blur="debounceValidateAddress()"
                                />
                            </div>
                            <div class="col-span-3 sm:col-span-2">
                                <FormKit
                                    v-model="address.street_number_addition"
                                    :errors="form.errors.street_number_addition"
                                    :label="$t('Addition')"
                                    autocomplete="address-line2"
                                    name="street_number_addition"
                                    type="text"
                                />
                            </div>

                            <div class="col-span-3 sm:col-span-2">
                                <FormKit
                                    v-model="address.street_name"
                                    :errors="form.errors.street_name"
                                    :label="$t('Street name')"
                                    autocomplete="off"
                                    name="street_name"
                                    type="text"
                                />
                            </div>
                            <div class="col-span-3 sm:col-span-2">
                                <FormKit
                                    v-model="address.city"
                                    :errors="form.errors.city"
                                    :label="$t('City')"
                                    autocomplete="off"
                                    name="city"
                                    type="text"
                                />
                            </div>
                            <div class="col-span-full">
                                <label class="col-span-full mb-3 ml-px block text-sm leading-6 text-gray-900">
                                    {{ $t("Invoice address (Optional)") }}
                                </label>
                                <FormKit
                                    v-model="sameAddress"
                                    :errors="form.errors.same_address"
                                    :label="$t('Same address')"
                                    :off-value="false"
                                    :on-value="true"
                                    name="same_address"
                                    type="checkbox"
                                />
                            </div>
                            <div
                                v-if="!sameAddress"
                                class="col-span-full grid grid-cols-6 gap-4 rounded-xl bg-lightGray-100 p-4"
                            >
                                <div class="col-span-full sm:col-span-2">
                                    <FormKit
                                        v-model="invoiceAddress.postal_code"
                                        :errors="form.errors.invoice_postal_code"
                                        :label="$t('Postalcode')"
                                        autocomplete="off"
                                        name="invoice_postal_code"
                                        type="text"
                                        @change="debounceUpdateMetaData"
                                    />
                                </div>
                                <div class="col-span-3 sm:col-span-2">
                                    <FormKit
                                        v-model="invoiceAddress.street_number"
                                        :errors="form.errors.invoice_street_number"
                                        :label="$t('Street number')"
                                        autocomplete="off"
                                        name="invoice_street_number"
                                        type="text"
                                        @change="debounceUpdateMetaData"
                                    />
                                </div>
                                <div class="col-span-3 sm:col-span-2">
                                    <FormKit
                                        v-model="invoiceAddress.street_number_addition"
                                        :errors="form.errors.invoice_street_number_addition"
                                        :label="$t('Addition')"
                                        autocomplete="address-line2"
                                        name="invoice_street_number_addition"
                                        type="text"
                                        @change="debounceUpdateMetaData"
                                    />
                                </div>

                                <div class="col-span-3 sm:col-span-2">
                                    <FormKit
                                        v-model="invoiceAddress.street_name"
                                        :errors="form.errors.invoice_street_name"
                                        :label="$t('Street name')"
                                        autocomplete="off"
                                        name="invoice_street_name"
                                        type="text"
                                        @change="debounceUpdateMetaData"
                                    />
                                </div>
                                <div class="col-span-3 sm:col-span-2">
                                    <FormKit
                                        v-model="invoiceAddress.city"
                                        :errors="form.errors.invoice_city"
                                        :label="$t('City')"
                                        autocomplete="off"
                                        name="invoice_city"
                                        type="text"
                                        @change="debounceUpdateMetaData"
                                    />
                                </div>
                            </div>

                            <div class="col-span-full">
                                <FormKit
                                    v-model="customer.email"
                                    :errors="form.errors.email"
                                    :label="$t('Email')"
                                    autocomplete="email"
                                    name="email"
                                    type="email"
                                    @change="debounceUpdateMetaData"
                                />
                            </div>
                            <div class="col-span-full">
                                <FormKit
                                    v-model="customer.phone_number"
                                    :errors="form.errors.phone_number"
                                    :label="$t('Phone number')"
                                    autocomplete="tel"
                                    name="phone_number"
                                    type="tel"
                                    @change="debounceUpdateMetaData"
                                />
                            </div>

                            <div class="col-span-full" />
                            <div class="col-span-full">
                                <FormKit
                                    v-model="remark"
                                    :errors="form.errors.remark"
                                    :label="$t('Remark')"
                                    name="remark"
                                    type="textarea"
                                    @change="debounceUpdateMetaData"
                                />
                            </div>
                        </div>

                        <div class="rounded-xl bg-white p-5">
                            <Button
                                :disabled="!(
                                    shoppingCart.address.distance_mijdrecht_forth !== null &&
                                    shoppingCart.address.distance_uithoorn_forth !== null
                                )
                                "
                                disable-style
                                @click.prevent="(shoppingCart.delivery_method = 'delivery'), resetDatePicker()"
                            >
                                <div class="flex items-center gap-3 font-bold">
                                    <img
                                        :src="shoppingCart.delivery_method === 'delivery' ? FilledDot : EmptyDot"
                                        alt="Dot"
                                        class="size-3"
                                    >
                                    <span>{{ $t("Deliver") }}</span>
                                </div>
                                <div class="ml-10 min-h-6">
                                    <span
                                        v-if="shoppingCart.address.distance_mijdrecht_forth !== null &&
                                            shoppingCart.address.distance_uithoorn_forth !== null
                                        "
                                    >
                                        {{ formatAddress(shoppingCart.address) }}
                                    </span>
                                    <span v-else>
                                        {{ $t("Firstly add your address") }}
                                    </span>
                                </div>
                            </Button>

                            <Button
                                disable-style
                                @click.prevent="(shoppingCart.delivery_method = 'pickup'), resetDatePicker()"
                            >
                                <div class="flex items-center gap-3 font-bold">
                                    <img
                                        :src="shoppingCart.delivery_method === 'pickup' ? FilledDot : EmptyDot"
                                        alt="Dot-icon"
                                        class="size-3"
                                    >
                                    <span>{{ $t("Pickup") }}</span>
                                </div>
                                <div class="ml-5 min-h-6 sm:ml-10">
                                    <Button
                                        v-for="location in locations"
                                        :disabled="!getAvailablePickupLocations
                                            .map((pickupLocation) => pickupLocation.id)
                                            .includes(location.id)
                                        "
                                        class="flex items-center gap-3"
                                        disable-style
                                        @click.prevent="shoppingCart.pickup_location = location.id"
                                    >
                                        <img
                                            :src="shoppingCart.pickup_location === location.id &&
                                                shoppingCart.delivery_method === 'pickup' &&
                                                getAvailablePickupLocations
                                                    .map((pickupLocation) => pickupLocation.id)
                                                    .includes(location.id)
                                                ? FilledDot
                                                : EmptyDot
                                            "
                                            alt="Dot-icon"
                                            class="size-3"
                                        >
                                        <span class="text-left"> {{ formatAddress(location.address) }}</span>
                                    </Button>
                                </div>
                            </Button>
                            <div
                                v-if="UnavailableLocationMessages.length && shoppingCart.delivery_method === 'pickup'"
                                class="mt-4 flex flex-col gap-2 text-sm font-bold text-red-600"
                            >
                                <div v-for="message in UnavailableLocationMessages">
                                    <p>{{ message.location.title }} {{ $t("Not available because of product(s):") }}</p>

                                    <div class="my-3 flex flex-wrap gap-2">
                                        <span
                                            v-for="product in message.products"
                                            class="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
                                        >
                                            {{ product.title }}
                                            <Button
                                                class="ml-0.5 inline-flex size-4 shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                                disable-style
                                                type="button"
                                                @click="shoppingCart.deleteProduct(product.id)"
                                            >
                                                <svg
                                                    class="size-2"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 8 8"
                                                >
                                                    <path
                                                        d="M1 1l6 6m0-6L1 7"
                                                        stroke-linecap="round"
                                                        stroke-width="1.5"
                                                    />
                                                </svg>
                                            </Button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="getOutOfDeliveryRangeProducts.length && shoppingCart.delivery_method === 'delivery'
                                "
                                class="mt-4 flex flex-col gap-2 text-sm font-bold text-red-600"
                            >
                                <div>
                                    <p>{{ $t("Product too far to deliver") }}</p>

                                    <div class="my-3 flex flex-wrap gap-2">
                                        <span
                                            v-for="product in getOutOfDeliveryRangeProducts"
                                            class="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
                                        >
                                            {{ product.title }}
                                            <Button
                                                class="ml-0.5 inline-flex size-4 shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                                disable-style
                                                type="button"
                                                @click="shoppingCart.deleteProduct(product.id)"
                                            >
                                                <svg
                                                    class="size-2"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 8 8"
                                                >
                                                    <path
                                                        d="M1 1l6 6m0-6L1 7"
                                                        stroke-linecap="round"
                                                        stroke-width="1.5"
                                                    />
                                                </svg>
                                            </Button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="shoppingCart.getOutOfOrderableRangeLines.length"
                                class="mt-4 flex flex-col gap-2 text-sm font-bold text-red-600"
                            >
                                <div>
                                    <p>{{ $t("Product(s) not within limits") }}</p>

                                    <div class="my-3 flex flex-wrap gap-2">
                                        <span
                                            v-for="line in shoppingCart.getOutOfOrderableRangeLines"
                                            class="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
                                        >
                                            {{ line.product.title }} -
                                            {{
                                                line.product.min_order ? "Min " + line.product.min_order : ""
                                            }}{{
                                                line.product.min_order && line.product.max_order ? ", " : ""
                                            }}{{ line.product.max_order ? "Max " + line.product.max_order : "" }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="shoppingCart.delivery_method"
                            class="rounded-xl bg-white p-5"
                        >
                            <label class="col-span-full mb-3 ml-px block text-sm leading-6 text-gray-900">
                                {{ $t("Date") }}
                            </label>
                            <div class="col-span-full">
                                <div
                                    class="formkit-inner mb-1 flex max-w-xl items-center rounded-full ring-1 ring-gray-400 focus-within:ring-2 focus-within:ring-primary-500 formkit-disabled:pointer-events-none formkit-disabled:cursor-not-allowed formkit-disabled:bg-gray-200 [&>label:first-child]:focus-within:text-primary-500"
                                >
                                    <VueDatePicker
                                        v-model="deliveryDate"
                                        :allowed-dates="availableTimes.map((date) => date.d)"
                                        :enable-time-picker="false"
                                        :locale="'nl'"
                                        auto-apply
                                        format="dd-MM-yyyy"
                                        model-type="yyyy-MM-dd"
                                        @closed="closePicker"
                                        @open="timeslotLoader"
                                        @update-month-year="timeslotLoader"
                                    >
                                        <template #dp-input="{ value, onInput, onEnter, onTab, onClear }">
                                            <input
                                                :placeholder="$t('Select a date')"
                                                :value="value"
                                                class="formkit-input w-full appearance-none border-none bg-transparent px-3 py-2 text-base text-gray-700 placeholder:text-gray-400 focus:shadow-none focus:outline-none focus:ring-0"
                                                inputmode="none"
                                                type="text"
                                            >
                                        </template>
                                    </VueDatePicker>
                                </div>

                                <FormKit
                                    v-if="deliveryDate"
                                    v-model="deliveryTime"
                                    :disabled="!availableTimes.find((date) => date.d === deliveryDate)?.t?.length"
                                    :ignore="true"
                                    :label="$t('Timeslot')"
                                    :options="availableTimes
                                        .find((date) => date.d === deliveryDate)
                                        ?.t?.map((date) => {
                                            return {
                                                value: date,
                                                label:
                                                    dayjs(date.t).format('LT') +
                                                    ' - ' +
                                                    dayjs(date.t).add(date.i, 'minute').format('LT'),
                                            };
                                        })
                                        .sort((a, b) => new Date(a.value) - new Date(b.value)) ?? []
                                    "
                                    :placeholder="$t('Select a timeslot')"
                                    type="select"
                                />
                            </div>
                        </div>

                        <div
                            v-if="formErrors?.delivery_date"
                            class="mb-4 text-red-500"
                        >
                            {{ $t("Delivery date is required") }}
                        </div>

                        <div class="rounded-xl bg-white p-5">
                            <label class="ml-px block text-sm leading-6 text-gray-900">
                                {{ $t("Coupon code") }}</label>
                            <div class="mt-2 grid grid-cols-6 gap-4">
                                <input
                                    v-model="discountCode"
                                    :placeholder="$t('Discount code')"
                                    class="form-input col-span-6 block w-full rounded-full border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:col-span-4 sm:text-sm sm:leading-6"
                                    @keyup.enter="applyCoupon()"
                                >

                                <Button
                                    v-if="!shoppingCart.coupon"
                                    class="col-span-6 sm:col-span-2"
                                    no-padding
                                    @click.prevent="applyCoupon()"
                                >
                                    {{ $t("Add") }}
                                </Button>

                                <Button
                                    v-if="shoppingCart.coupon"
                                    class="col-span-6 sm:col-span-2"
                                    no-padding
                                    @click.prevent="deleteCoupon()"
                                >
                                    {{ $t("Delete") }}
                                </Button>

                                <div
                                    v-if="invalidCode"
                                    class="col-span-6"
                                >
                                    {{ $t("Unavailable code") }}
                                </div>
                            </div>
                        </div>

                        <div class="px-5">
                            <div class="flex justify-end">
                                <div class="text-sm">
                                    {{ $t("Required fields") }}
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col gap-2 rounded-xl bg-white p-5">
                            <div
                                v-if="shoppingCart.getDeliveryCostsWithVatInCents"
                                class="flex justify-between"
                            >
                                <div class="font-bold">
                                    {{ $t("Delivery costs") }}
                                </div>

                                <div class="font-bold">
                                    {{ $n(shoppingCart.getDeliveryCostsWithVatInCents / 100, "currency", "nl") }}
                                </div>
                            </div>
                            <div
                                v-if="shoppingCart.getDiscountInCents"
                                class="flex justify-between"
                            >
                                <div class="font-bold">
                                    {{ $t("Discount") }}
                                </div>

                                <div class="font-bold">
                                    {{ $n(-Math.abs(shoppingCart.getDiscountInCents) / 100, "currency", "nl") }}
                                </div>
                            </div>
                            <div class="flex flex-col justify-between sm:flex-row">
                                <div class="font-bold">
                                    {{ $t("Total") }}
                                </div>

                                <div class="font-bold">
                                    {{ $n(shoppingCart.getPayableTotalInCents / 100, "currency", "nl") }} (
                                    {{ $t("Including") }}
                                    {{ $n(shoppingCart.getVatInCents / 100, "currency", "nl") }} {{ $t("TAX") }} )
                                </div>
                            </div>
                        </div>

                        <MinPriceForDeliverySign />


                        <div class="px-5">
                            <FormKit
                                v-model="form.terms"
                                :errors="form.errors.terms"
                                :label="$t('I agree with the terms and conditions and have checked my order and details *')
                                "
                                name="terms"
                                type="checkbox"
                            />
                        </div>

                        <div class="px-5">
                            <FormKit
                                v-model="form.newsletter"
                                :errors="form.errors.newsletter"
                                :label="$t('I would like to receive a newsletter from De Vers Specialist.')"
                                name="newsletter"
                                type="checkbox"
                            />
                        </div>
                    </div>

                    <div class="flex flex-col gap-6 p-5">
                        <Button
                            :color="form.hasErrors ? 'error' : 'primary'"
                            :disabled="!!(
                                checkoutDisabled ||
                                (shoppingCart.delivery_method === 'delivery' && !shoppingCart.minPriceForDeliveryHasBeenReached) ||
                                shoppingCart.getOutOfOrderableRangeLines.length
                            )
                            "
                            class="col-span-2"
                            @click="sendForm"
                        >
                            {{ $t("Pay") }}
                        </Button>
                        <Button
                            class="col-span-2"
                            route-name="public.home"
                        >
                            {{ $t("Back") }}
                        </Button>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="mx-auto mb-12 flex w-4/5 text-xl font-bold"
            >
                <span>{{ $t("No products in shopping cart yet") }}</span>
            </div>
        </div>
    </PublicLayout>
</template>

<style>
.dp__theme_light {
    --dp-primary-color: #ef7d17;
}
</style>
