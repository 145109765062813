<script setup>
import Checkmark from '@/assets/images/banner-checkmark.svg';
import Star from '@/assets/images/banner-star.svg';
import Truck from '@/assets/images/banner-truck.svg';
import Logo from '@/assets/images/logo.svg';
import ShoppingBag from '@/assets/images/shopping-bag.svg';
import {Bars3Icon} from '@heroicons/vue/20/solid';
import {useScroll} from '@vueuse/core';
import Button from '@/Components/Inputs/Button.vue';
import SideNav from '@/Components/Layout/SideNav.vue';
import ShoppingCart from '@/Components/Modals/ShoppingCart.vue';
import {useCartStore} from '@/stores/cart';
import {inject, onMounted, ref} from 'vue';

const scroller = ref();
onMounted(() => {
    scroller.value = window;
});
const {y} = useScroll(scroller);

const dayjs = inject('dayjs');

const route = inject('route');

const sidebarOpen = ref(false);

const shoppingCart = useCartStore();
</script>

<template>
    <header>
        <div
            :class="y > 30 ? 'shadow-md' : ''"
            class="fixed left-0 top-0 z-40 w-full bg-secondary-400"
        >
            <div class="mx-auto max-w-7xl px-4 sm:px-6">
                <div class="flex">
                    <Button
                        class="-mb-4 flex min-h-18 min-w-17 items-center justify-center rounded-b-xl bg-primary-400 shadow-lg sm:-mb-6 sm:min-h-24 sm:min-w-23"
                        disable-style
                        routeName="public.home"
                    >
                        <span class="sr-only">Home</span>
                        <img
                            :src="Logo"
                            alt="Logo"
                            class="size-14 sm:size-17"
                            height="24"
                            width="24"
                        >
                    </Button>
                    <div class="flex min-h-21 w-full items-center justify-between py-4">
                        <Button
                            class="text-md ml-4 font-bold uppercase text-primary-400 sm:ml-5 sm:text-2xl"
                            disable-style
                            routeName="public.home"
                        >
                            De Vers Specialist
                        </Button>

                        <div class="ml-4 flex gap-3 sm:ml-5 sm:gap-5">
                            <button
                                class="relative flex size-10 items-center justify-center rounded-full bg-white px-3 py-2 text-sm font-bold uppercase sm:size-12 md:min-w-38"
                                @click="shoppingCart.cartSidebar = true"
                            >
                                <img
                                    :alt="$t('Cart')"
                                    :src="ShoppingBag"
                                    class="size-5 md:mr-2"
                                    height="24"
                                    width="24"
                                >

                                <span class="hidden md:flex">{{
                                        $n(shoppingCart.getPayableTotalInCents / 100, 'currency', 'nl')
                                    }}</span>

                                <div
                                    class="absolute -left-3 flex size-6 items-center justify-center rounded-full bg-primary-400 text-white md:-top-2 md:left-auto md:right-1"
                                >
                                    {{ shoppingCart.cartCount }}
                                </div>
                            </button>
                            <button
                                class="flex items-center justify-center text-primary-400"
                                @click="sidebarOpen = true"
                            >
                                <span class="sr-only">{{ $t('Open menu') }}</span>

                                <bars3-icon class="size-6"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="dayjs().format('YYYY-MM-DD') < dayjs('2024-08-11').format('YYYY-MM-DD')"
                class="flex-center mx-auto min-h-16 max-w-7xl  px-4 text-center text-xl sm:px-6"
            >
                <div class="text-sm leading-none text-primary-400 md:text-xl">
                    Wij bezorgen <strong> niet </strong> in week 31 & 32. Excuses voor het ongemak.
                </div>
            </div>
        </div>
        <div class="mt-36 bg-primary-400">
            <div class="mx-auto max-w-7xl px-4 sm:px-6">
                <div class="flex w-full items-center justify-evenly space-x-6 py-4 font-bold text-secondary-400">
                    <div class="flex items-center">
                        <img
                            :alt="$t('Checkmark')"
                            :src="Checkmark"
                            class="mr-4 size-8"
                            height="24"
                            width="24"
                        >
                        <span>{{ $t('100% fresh guaranteed') }}</span>
                    </div>
                    <div class="hidden items-center sm:flex">
                        <img
                            :alt="$t('Star')"
                            :src="Star"
                            class="mr-4 size-8"
                            height="24"
                            width="24"
                        >

                        <span>{{ $t('The highest quality') }}</span>
                    </div>
                    <div class="flex hidden items-center sm:flex">
                        <img
                            :alt="$t('Car')"
                            :src="Truck"
                            class="mr-4 size-8"
                            height="24"
                            width="24"
                        >

                        <span>{{ $t('Fast home delivery') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <SideNav
        :show="sidebarOpen"
        @close="sidebarOpen = false"
    />

    <ShoppingCart/>
</template>
