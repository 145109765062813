<script setup>
import {useCartStore} from "@/stores/cart.js";

const shoppingCart = useCartStore();
</script>

<template>
    <div
        v-if="shoppingCart.delivery_method === 'delivery' && !shoppingCart.minPriceForDeliveryHasBeenReached"
        class="flex flex-col gap-2 rounded-xl bg-white p-5"
    >
        <div class="flex flex-col justify-between sm:flex-row">
            <div class="font-bold text-red-600">
                {{
                    $t("The minimum order amount of {amount} has not yet been reached", {
                        amount: $n(
                            shoppingCart.minPriceForDelivery / 100,
                            "currency",
                            "nl"
                        )
                    })
                }}
            </div>
        </div>
    </div>
</template>
