<script setup>
import ArrowRight from '@/assets/images/arrow-right.svg';
import {inject} from 'vue';
import Button from '@/Components/Inputs/Button.vue';
import GeneralSidebar from '@/Components/Modals/GeneralSidebar.vue';
import TextHeader from '@/Components/Partitials/TextHeader.vue';

const route = inject('route');

const props = defineProps({
    show: Boolean,
});
</script>

<template>
    <GeneralSidebar :show="show">
        <div class="flex justify-between py-6">
            <button @click="$emit('close')">
                <img
                    alt="Arrow right"
                    :src="ArrowRight"
                    class="size-6"
                    height="24"
                    width="24"
                >
            </button>
        </div>

        <TextHeader
            class="mb-6"
            title="Menu"
        />

        <div class="mt-5 grow">
            <nav class="flex flex-col gap-6">
                <Button
                    full-width
                    routeName="public.home"
                >
                    {{ $t('Home') }}
                </Button>
                <Button
                    full-width
                    routeName="public.contact"
                >
                    {{ $t('Contact') }}
                </Button>
                <Button
                    full-width
                    routeName="public.about-us"
                >
                    {{ $t('About us') }}
                </Button>
                <Button
                    full-width
                    routeName="public.faq"
                >
                    {{ $t('FAQ') }}
                </Button>
            </nav>
        </div>

        <div class="flex flex-col items-center">
            <div class="mb-4 h-px w-full bg-lightGray-600"/>
            <span>2023 © De Vers Specialist - {{ $t('Realised by:') }} STRIXI</span>
        </div>
    </GeneralSidebar>
</template>
