import {defineStore} from "pinia";
import {arrayUniqueByKey} from "@/utilities.js";

export const useCartStore = defineStore("shopping-cart", {
    state: () => ({
        lines: [],
        ecommerceSettings: {},
        crossSellOpened: false,

        cartSidebar: false,
        productConfigurator: false,
        productConfiguratorProduct: null,

        delivery_method: null,
        pickup_location: 1,

        coupon: null,
        lastAddedProductId: null,

        same_address: true,
        address: {
            postal_code: "",
            street_name: "",
            street_number: "",
            addition: "",
            city: "",
            distance_mijdrecht_forth: null,
            distance_uithoorn_forth: null,
        },

        invoice_address: {
            invoice_postal_code: "",
            invoice_street_name: "",
            invoice_street_number: "",
            invoice_city: "",
            invoice_street_number_addition: "",
        },

        remark: "",

        customer: {
            fist_name: "",
            last_name: "",
            company: "",
            email: "",
            phone: "",
        },
    }),
    getters: {
        products: (state) => state.lines.map((line) => line.product),
        getLastProduct: (state) => state.lines[state.lines.length - 1],
        lastAddedProduct: (state) => state.lines.find((line) => line.product_id === state.lastAddedProductId),
        cartCount: (state) => state.lines.reduce((p, c) => p + c.quantity, 0),

        delivery_costs: (state) => {
            return state.locationId === 1 ? state.schema.mijdrecht_delivery_costs : state.schema.delivery_costs
        },

        minPriceForDelivery: (state) => {
            return state.locationId === 1 ? state.schema.mijdrecht_min_price_for_delivery : state.schema.min_price_for_delivery
        },

        categories: (state) => {
            return arrayUniqueByKey(state.products
                .map((product) => product.schema_data)
                .flat());
        },

        schemas: (state) => {
            return arrayUniqueByKey(state.categories
                .map((category) => category.category_schema)
                .flat());
        },

        schema: (state) => {
            return state.schemas.reduce((prev, current) => {
                let prevDeliveryCosts = prev.location_id === 1 ? prev.mijdrecht_delivery_costs : prev.delivery_costs;
                let currentDeliveryCosts = current.location_id === 1 ? current.mijdrecht_delivery_costs : current.delivery_costs;

                return prevDeliveryCosts > currentDeliveryCosts ? prev : current;
            });
        },

        locationId: (state) => {
            return parseInt(state.address?.preferred_deliverer ?? 1);
        },

        minPriceForDeliveryHasBeenReached: (state) => {
            return state.getPayableTotalInCents - state.getDeliveryCostsWithVatInCents > state.minPriceForDelivery;
        },

        uncombinableCategories: (state) =>
            state.products
                .map((product) => product.schema_data)
                .map((productCategories) => productCategories.map((category) => category.uncombinable))
                .flat(3),

        getSubtotalInCents(state) {
            let totalInCents = 0;

            state.lines.forEach((line) => {
                totalInCents +=
                    line.quantity *
                    (line.product.is_discount_active
                        ? line.product.discounted_price_excluding_vat
                        : line.product.price_excluding_vat);
            });

            return totalInCents;
        },

        getSubtotalWithVatInCents(state) {
            let totalInCents = 0;

            state.lines.forEach((line) => {
                totalInCents +=
                    line.quantity *
                    (line.product.is_discount_active
                        ? line.product.discounted_price_including_vat
                        : line.product.price_including_vat);
            });

            return totalInCents;
        },

        getDeposit(state) {
            let totalInCents = 0;

            state.lines.forEach((line) => {
                if (line.product.is_deposit) {
                    totalInCents +=
                        line.quantity *
                        (line.product.is_discount_active
                            ? line.product.discounted_price_including_vat
                            : line.product.price_including_vat);
                }
            });

            return totalInCents;
        },

        getTotalInCents() {
            return this.getSubtotalWithVatInCents + this.getDeliveryCostsWithVatInCents;
        },

        getPayableTotalInCents() {
            return (
                this.subtractDiscount(this.getSubtotalWithVatInCents - this.getDeposit) +
                this.getDeposit +
                this.getDeliveryCostsWithVatInCents
            );
        },


        getDiscountInCents() {
            return this.getTotalInCents - this.getPayableTotalInCents;
        },

        getVatInCents() {
            //Change if Deposit ever gets VAT
            return (
                this.subtractDiscount(this.getSubtotalWithVatInCents - this.getSubtotalInCents) +
                this.getDeliveryCostsVatInCents
            );
        },

        getDeliveryCostsInCents() {
            return (
                (this.getDeliveryCostsWithVatInCents /
                    (100 + Math.max(...this.products.map((product) => product.vat_rate)))) *
                100
            );
        },

        getDeliveryCostsWithVatInCents(state) {
            if (state.delivery_method === "delivery") {
                return state.delivery_costs;
            }
            return 0;
        },

        getDeliveryCostsVatInCents() {
            return this.getDeliveryCostsWithVatInCents - this.getDeliveryCostsInCents;
        },

        exceededPriceLimit(state) {
            if (state.lines.length === 0) {
                return false;
            }
            const total = state.lines.reduce((p, c) => p + c.product.price_including_vat * c.quantity, 0);
            return total > 200000;
        },

        getOutOfOrderableRangeLines(state) {
            return state.lines.filter(
                (line) =>
                    (line.product.min_order && line.quantity < line.product.min_order) ||
                    (line.product.max_order && line.quantity > line.product.max_order))
        }
    },
    actions: {
        subtractDiscount(price) {
            if (this.coupon?.value) {
                // Check the type of discount
                if (this.coupon.type === "percentage") {
                    let discountedPrice = price - (price / 100) * parseInt(this.coupon.value);

                    return Math.round(discountedPrice);
                }
                if (this.coupon.type === "fixed") {
                    let percentage =
                        (parseInt(this.coupon.value) / (this.getSubtotalWithVatInCents - this.getDeposit)) * 100;

                    let discountedPrice = price - (price / 100) * percentage;
                    return Math.round(discountedPrice);
                }
            }

            return Math.round(price);
        },

        toggleCrossSell() {
            this.crossSellOpened = !this.crossSellOpened;
        },

        interactWithProduct(product = null) {
            this.productConfiguratorProduct = product;
            this.openProductConfigurator();
        },

        closeProductConfigurator() {
            this.productConfiguratorProduct = null;
            this.productConfigurator = false;
        },

        openProductConfigurator() {
            if (this.productConfiguratorProduct.children_count || this.productConfiguratorProduct.extras_count) {
                this.productConfigurator = true;
            } else {
                this.setProduct(this.productConfiguratorProduct.id, this.productConfiguratorProduct.min_order ?? 1);
                this.productConfiguratorProduct = null;
                this.cartSidebar = true;
            }
        },

        load(shoppingCart, route) {
            this.lines = shoppingCart.lines.sort((a, b) => a.created_at > b.created_at);
            this.removeUnavailableProducts();

            if (shoppingCart.formatted_coupon !== null) {
                this.coupon = shoppingCart.formatted_coupon;
            }

            if (shoppingCart.metadata?.address) {
                this.address = shoppingCart.metadata.address;
            }

            if (shoppingCart.metadata?.invoice_address) {
                this.invoice_address = shoppingCart.metadata.invoice_address;
            }

            if (shoppingCart.metadata?.customer) {
                this.customer = shoppingCart.metadata.customer;
            }

            if (shoppingCart.metadata?.remark) {
                this.remark = shoppingCart.metadata.remark;
            }

            if (shoppingCart.metadata) {
                if (typeof shoppingCart.metadata.same_address !== "undefined")
                    this.same_address = !!parseInt(shoppingCart.metadata.same_address);
            }

            this.route = route;
        },

        async setCoupon(code) {
            this.coupon = await this.postCoupon(code);
        },

        async postCoupon(code) {
            return await window.axios
                .post(this.route("api.commerce.coupon.store"), {code: code})
                .then(function (response) {
                    return response.data.coupon;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async deleteCoupon(code) {
            await this.postDeleteCoupon(code);
            this.coupon = null;
        },

        async postDeleteCoupon() {
            return await window.axios
                .delete(this.route("api.commerce.coupon.destroy"))
                .then(function (response) {
                    return response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async setProduct(product_id, quantity = 1) {
            //Product already in cart
            let existingProduct = this.lines.find((element) => element.product.id === product_id);
            if (existingProduct) quantity = quantity + existingProduct.quantity;
            let newLine = await this.postLine(product_id, quantity);

            await this.updateOrReplace(this.lines, newLine, (line, needle) => line.id === needle.id);

            this.lastAddedProductId = product_id;
            this.crossSellOpened = true;
        },

        async deleteProduct(product_id) {
            const lineToBeDeleted = this.lines.find((line) => line.product_id === product_id);

            if (typeof lineToBeDeleted == "undefined") {
                console.warn("Tried to delete product that is not in cart");
                return;
            }

            await this.deleteLine(lineToBeDeleted.id);

            this.lines = this.lines.filter((line) => line.id !== lineToBeDeleted.id);
        },

        async setQuantity(line, amount) {
            let quantity = parseInt(amount);

            if (quantity === 0) {
                await this.deleteProduct(line.product.id);
                return;
            }

            let newLine = await this.postLine(line.product.id, quantity, line.remark);
            this.updateOrReplace(this.lines, newLine, (line, needle) => line.id === needle.id);
        },

        async setRemark(line, remark = "") {
            let quantity = parseInt(line.quantity);
            let newLine = await this.postLine(line.product.id, quantity, remark);
            this.updateOrReplace(this.lines, newLine, (line, needle) => line.id === needle.id);
        },

        async postLine(product_id, quantity, remark = "") {
            return await window.axios
                .post(this.route("api.shopping-cart-line.store"), {product_id, quantity, remark})
                .then(function (response) {
                    return response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async deleteLine(id) {
            return await window.axios
                .delete(this.route("api.shopping-cart-line.destroy", id))
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getQuantity(product_id) {
            const currentLine = this.lines.find((line) => line.product_id === product_id);

            if (currentLine) {
                return currentLine.quantity;
            }
            return 0;
        },

        async removeOneProduct(product_id) {
            let currentLine = this.lines.find((line) => line.product_id === product_id);

            // Add minus one product on click
            if (this.getQuantity(product_id) !== 1) {
                await this.setProduct(product_id, this.getQuantity(product_id) - 1);
            } else {
                await this.deleteProduct(product_id);
            }

            if (currentLine?.quantity) {
                currentLine.quantity = this.getQuantity(product_id) - 1;
            }
        },

        async setEcommerceSettings(settings) {
            this.ecommerceSettings = {
                ...settings,
            };
        },

        async addOneProduct(product_id) {
            await this.setProduct(product_id, parseInt(this.getQuantity(product_id)) + 1);
        },

        updateOrReplace(haystack, needle, compare) {
            let index = haystack.findIndex((line) => compare(line, needle));
            if (index === -1) {
                haystack.push(needle);
            } else {
                haystack[index] = needle;
            }
        },

        removeUnavailableProducts() {
            this.lines = this.lines.filter((line) => line?.product);
        },

        getVariantPriceAddition(product, variant) {
            return this.calculatePayableProductPrice(variant) - this.calculatePayableProductPrice(product);
        },

        calculatePayableProductPrice(product) {
            return product.is_discount_active ? product.discounted_price_including_vat : product.price_including_vat;
        },

        getProductDiscount(product) {
            return product.is_discount_active ? product.discounted_price_including_vat : null;
        },

        async setDeliveryMethod(method) {
            this.delivery_method = method;
        },
    },
});
