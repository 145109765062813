<script setup>
import ArrowRight from '@/assets/images/arrow-right.svg';
import { inject } from 'vue';

import CartItems from '@/Components/CartItems.vue';
import Button from '@/Components/Inputs/Button.vue';
import GeneralSidebar from '@/Components/Modals/GeneralSidebar.vue';
import TextHeader from '@/Components/Partitials/TextHeader.vue';
import { useCartStore } from '@/stores/cart';

const route = inject('route');

const props = defineProps({
    show: Boolean,
});

const shoppingCart = useCartStore();
</script>

<template>
    <GeneralSidebar
        :show="shoppingCart.cartSidebar"
        size="big"
        @close="shoppingCart.cartSidebar = false"
    >
        <div class="flex justify-between py-6">
            <button @click="shoppingCart.cartSidebar = false">
                <img
                    alt="Arrow right"
                    :src="ArrowRight"
                    class="size-6"
                    height="24"
                    width="24"
                >
            </button>
        </div>
        <TextHeader
            class="mb-6"
            :title="$t('Overview')"
        />

        <div
            v-if="shoppingCart.products.length"
            class="mt-5 grow overflow-y-auto pb-6 pt-2 text-center"
        >
            <CartItems />
        </div>

        <div
            v-else
            class="mt-5 grow overflow-y-auto text-center"
        >
            <div class="text-xl font-bold">
                <span> {{ $t('No products in your shopping cart yet') }} </span>
            </div>
        </div>

        <div class="flex flex-col">
            <div class="mb-4 h-[2px] w-full bg-lightGray-400" />
            <div class="flex justify-between font-bold">
                <div>
                    <span>{{ $t('Total') }}</span>
                </div>

                <div>
                    <span>{{ $n(shoppingCart.getPayableTotalInCents / 100, 'currency', 'nl') }}</span>
                </div>
            </div>
            <Button
                :disabled="!shoppingCart.products.length"
                class="my-4 sm:mb-12 sm:mt-8"
                full-width
                routeName="public.checkout"
                @click="shoppingCart.cartSidebar = false"
            >
                {{ $t('Order now') }}
            </Button>
        </div>
    </GeneralSidebar>
</template>
