<script setup>
import About from '@/assets/images/about.webp';
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { computed, inject } from 'vue';

import Breadcrumb from '@/Components/Breadcrumb.vue';
import Button from '@/Components/Inputs/Button.vue';
import TextHeader from '@/Components/Partitials/TextHeader.vue';

const props = defineProps({
    page: Object,
});

const route = inject('route');

const PageContent = computed(() => {
    return props.page.blocks.find((element) => element.component_name === 'content');
});
</script>

<template>
    <PublicLayout
        :description="page?.seo_description"
        :title="page?.seo_title"
    >
        <Breadcrumb :pages="[{ name: $t('About us'), href: route('public.about-us'), current: true }]" />

        <div class="mx-auto max-w-7xl px-4 pb-8 pt-10 sm:px-6 sm:pt-24">
            <TextHeader
                :title="$t('About us')"
                class="mx-auto mb-12 w-4/5"
            />

            <div class="grid grid-cols-1 gap-8 md:grid-cols-2">
                <div class="md:order-last">
                    <img
                        :src="About"
                        alt="Over ons"
                        class="w-full rounded-lg"
                    >
                </div>

                <div>
                    <div
                        class="prose prose-lg"
                        v-html="PageContent.content.text"
                    />

                    <div class="mt-4">
                        <Button
                            routeName="public.contact"
                            size="large"
                        >
                            {{ $t('Contact') }}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </PublicLayout>
</template>
