<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/shared';
import { computed, inject, watch } from 'vue';

import Button from '@/Components/Inputs/Button.vue';
import Select from '@/Components/Inputs/Select.vue';

const route = inject('route');

const props = defineProps({
    query: Object,
    routeName: String,
    categories: Object,
    processing: Boolean,
});

const emits = defineEmits(['update:processing']);

const form = useForm({
    search: props.query.search ?? '',
    category_ids: props.query.category_ids ?? [],
    sort: props.query.sort ?? '',
});

watch(
    () => form.processing,
    () => {
        emits('update:processing', form.processing);
    }
);

watch(
    () => form.category_ids,
    () => {
        getData();
    }
);

watch(
    () => props.query.sort,
    () => {
        form.sort = props.query.sort;
        getData();
    }
);

const getData = () => {
    form.get(route(props.routeName ?? 'public.home'), {
        preserveState: true,
        preserveScroll: true,
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const visibleCategories = computed(() => {
    return props.categories.filter((category) => {
        return category.is_visible;
    });
});
</script>

<template>
    <div class="flex w-full flex-col gap-6 sm:gap-8">
        <h2 class="block text-2xl font-bold uppercase leading-6 text-gray-900">
            {{ $t('Category') }}
        </h2>

        <div>
            <label
                class="text-md hidden font-medium leading-6 text-gray-900 sm:block"
                for="search"
            >{{
                $t('Search:')
            }}</label>
            <div class="relative mt-2 rounded-md">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon
                        aria-hidden="true"
                        class="size-5 text-gray-400"
                    />
                </div>
                <input
                    id="search"
                    v-model="form.search"
                    :placeholder="$t('E.g. Chicken, Turky, Dinners')"
                    class="form-input block w-full rounded-full border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    name="email"
                    type="search"
                    @input="debouncedGetData"
                >
            </div>
        </div>

        <div class="sm:hidden">
            <div>
                <Select
                    v-model="form.category_ids"
                    :label="$t('Categories')"
                    :options="visibleCategories"
                />
            </div>
        </div>

        <div class="hidden space-y-5 sm:block">
            <div
                v-for="(category, key) in visibleCategories"
                :key="category.id"
                class="flex"
            >
                <div class="flex h-6 items-center">
                    <input
                        :id="'category-' + key"
                        v-model="form.category_ids"
                        :value="category.id"
                        aria-describedby="comments-description"
                        class="form-checkbox size-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                        name="categories"
                        type="checkbox"
                    >
                </div>
                <div class="ml-3 text-sm leading-6">
                    <label
                        :for="'category-' + key"
                        class="font-medium text-gray-900"
                    >{{ category.title }}</label>
                </div>
            </div>
        </div>

        <Button
            class="text-sm font-bold uppercase hover:text-primary-400"
            disable-style
            routeName="public.categories.index"
        >
            {{ $t('All categories') }}
        </Button>

    <!--        <div class='p-5 mt-4 bg-lightGray-400 rounded-xl'>-->
    <!--            <span>Categorie niet beschikbaar i.c.m. een product in uw winkelmandje.</span>-->
    <!--        </div>-->
    </div>
</template>
