export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more error)|(and {count} more errors)|(and {count} more errors)",
        "100% fresh guaranteed": "100% fresh guaranteed",
        "{count} characters": "{count} characters",
        "{weight} g": "{weight} g",
        "{weight} kg": "{weight} kg",
        "A decryption key is required.": "A decryption key is required.",
        "About de vers specialist": "About de vers specialist",
        "about us": "about us",
        "About us": "About us",
        "Active": "Active",
        "Active until": "Active until",
        "Add": "Add",
        "Add page block": "Add page block",
        "Addition": "Addition",
        "Address": "Address",
        "Address not valid": "Address not valid",
        "All categories": "All categories",
        "All related products": "All related products",
        "All rights reserved.": "All rights reserved.",
        "Allergens": "Allergens",
        "Alt text": "Alt text",
        "Always show banner": "Always show banner",
        "Amount of products:": "Amount of products:",
        "Answer": "Answer",
        "Back": "Back",
        "Back to home": "Back to home",
        "Billing address": "Billing address",
        "Block day": "Block day",
        "Car": "Car",
        "Cart": "Cart",
        "Categories": "Categories",
        "Category": "Category",
        "Category schema": "Category schema",
        "Category schemas": "Category schemas",
        "Checkmark": "Checkmark",
        "Checkout": "Checkout",
        "Chicken icon": "Chicken icon",
        "City": "City",
        "Clock": "Clock",
        "Close": "Close",
        "Code": "Code",
        "Column order": "Column order",
        "Company details": "Company details",
        "Company name": "Company name",
        "Confirmation {source}": "Confirmation {source}",
        "Contact": "Contact",
        "Contact De Vers Specialist": "Contact De Vers Specialist",
        "Contacts": "Contacts",
        "Coupon": "Coupon",
        "Coupon added": "Coupon added",
        "Coupon after order": "Coupon after order",
        "Coupon applied": "Coupon applied",
        "Coupon can no longer be used": "Coupon can no longer be used",
        "Coupon code": "Coupon code",
        "Coupon deleted": "Coupon deleted",
        "Coupon invalid": "Coupon invalid",
        "Coupon is no available anymore": "Coupon is no available anymore",
        "Coupon not found": "Coupon not found",
        "Coupon successfully added": "Coupon successfully added",
        "Coupon successfully deleted": "Coupon successfully deleted",
        "Coupon type": "Coupon type",
        "Coupon value fixed": "Coupon value fixed",
        "Coupon value percentage": "Coupon value percentage",
        "Coupons": "Coupons",
        "Created at": "Created at",
        "CSV file with `id` and `price` columns": "CSV file with `id` and `price` columns",
        "Custom": "Custom",
        "Customer": "Customer",
        "Customers": "Customers",
        "Date": "Date",
        "Default": "Default",
        "Delete": "Delete",
        "Delete product": "Delete product",
        "Deleted at": "Deleted at",
        "Deliver": "Deliver",
        "Delivery": "Delivery",
        "Delivery costs": "Delivery costs",
        "Delivery date is required": "Delivery date is required",
        "Delivery drivers": "Delivery drivers",
        "Delivery location": "Delivery location",
        "Delivery method": "Delivery method",
        "Description": "Description",
        "description": "description",
        "Disabling a coupon will prevent it from being used": "Disabling a coupon will prevent it from being used",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Discount code": "Discount code",
        "Discount code copied": "Discount code copied",
        "Discount code could not be copied": "Discount code could not be copied",
        "Discounted price": "Discounted price",
        "Display type": "Display type",
        "Do you have any questions or can't find what you're looking for?": "Do you have any questions or can't find what you're looking for?",
        "Download packing slip": "Download packing slip",
        "E.g. Chicken, Turky, Dinners": "E.g. Chicken, Turky, Dinners",
        "Ecommerce settings": "Ecommerce settings",
        "Email": "Email",
        "Email address": "Email address",
        "Encrypted environment file already exists.": "Encrypted environment file already exists.",
        "Encrypted environment file not found.": "Encrypted environment file not found.",
        "End": "End",
        "Environment file already exists.": "Environment file already exists.",
        "Environment file not found.": "Environment file not found.",
        "errors": "errors",
        "Extra product": "Extra product",
        "Extra products": "Extra products",
        "Extra's": "Extra's",
        "FAQ": "FAQ",
        "Fast home delivery": "Fast home delivery",
        "Fill in another address": "Fill in another address",
        "First name": "First name",
        "Firstly add your address": "Firstly add your address",
        "Fixed": "Fixed",
        "Forbidden": "Forbidden",
        "Frequently asked question": "Frequently asked question",
        "Frequently asked questions": "Frequently asked questions",
        "Frequintly asked questions": "Frequintly asked questions",
        "Go to page {page}": "Go to page {page}",
        "Grams": "Grams",
        "Handler": "Handler",
        "Heading": "Heading",
        "Hello!": "Hello!",
        "Hero": "Hero",
        "Hero image": "Hero image",
        "Hide on header": "Hide on header",
        "Hits": "Hits",
        "Home": "Home",
        "I agree with the terms and conditions and have checked my order and details *": "I agree with the terms and conditions and have checked my order and details *",
        "I would like to receive a newsletter from De Vers Specialist.": "I would like to receive a newsletter from De Vers Specialist.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "Image": "Image",
        "Images": "Images",
        "Including": "Including",
        "Interval": "Interval",
        "Invalid filename.": "Invalid filename.",
        "Invalid JSON was returned from the route.": "Invalid JSON was returned from the route.",
        "Invoice address (Optional)": "Invoice address (Optional)",
        "Last name": "Last name",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to generate a random code. Not editable after creation.": "Leave empty to generate a random code. Not editable after creation.",
        "length": "length",
        "Level": "Level",
        "List of categories": "List of categories",
        "List of products": "List of products",
        "Location": "Location",
        "Locations": "Locations",
        "Login": "Login",
        "Logout": "Logout",
        "Make your decision:": "Make your decision:",
        "Max in order": "Max in order",
        "Max kilometer": "Max kilometer",
        "Max orders": "Max orders",
        "Max users": "Max users",
        "Message": "Message",
        "Message sent": "Message sent",
        "Middle name": "Middle name",
        "Mijdrecht: delivery costs": "Mijdrecht: delivery costs",
        "Mijdrecht: min price for delivery": "Mijdrecht: min price for delivery",
        "Min in order": "Min in order",
        "Min preparation in hours": "Min preparation in hours",
        "Min price for delivery": "Min price for delivery",
        "Most sold": "Most sold",
        "Name": "Name",
        "New orders": "New orders",
        "New URL": "New URL",
        "Next": "Next",
        "No": "No",
        "No frequently asked questions found": "No frequently asked questions found",
        "No products found": "No products found",
        "No products in shopping cart yet": "No products in shopping cart yet",
        "No products in your shopping cart yet": "No products in your shopping cart yet",
        "Not available because of product(s):": "Not available because of product(s):",
        "Not available in this combination": "Not available in this combination",
        "Not available with product(s):": "Not available with product(s):",
        "Not editable after creation.": "Not editable after creation.",
        "Not Found": "Not Found",
        "Not visible in shop but orderable in combination with other products": "Not visible in shop but orderable in combination with other products",
        "of": "of",
        "Old URL": "Old URL",
        "Open menu": "Open menu",
        "Opening hours": "Opening hours",
        "Opening hours - Dutch": "Opening hours - Dutch",
        "Opening hours - English": "Opening hours - English",
        "Order": "Order",
        "Order column": "Order column",
        "Order Confirmation": "Order Confirmation",
        "Order date:": "Order date:",
        "Order now": "Order now",
        "Order online": "Order online",
        "Order received": "Order received",
        "Orderable and visible": "Orderable and visible",
        "Ordernumber:": "Ordernumber:",
        "Orders": "Orders",
        "Overview": "Overview",
        "Page": "Page",
        "Page blocks": "Page blocks",
        "Page Expired": "Page Expired",
        "Page not found": "Page not found",
        "Page SEO": "Page SEO",
        "Page setting": "Page setting",
        "Page settings": "Page settings",
        "Pages": "Pages",
        "Pagination Navigation": "Pagination Navigation",
        "Paragraph": "Paragraph",
        "Password": "Password",
        "Pay": "Pay",
        "Payment": "Payment",
        "Payment methods": "Payment methods",
        "Payment Required": "Payment Required",
        "Per interval": "Per interval",
        "Percentage": "Percentage",
        "Permanent": "Permanent",
        "Phone number": "Phone number",
        "Pickup": "Pickup",
        "Pickup location": "Pickup location",
        "Pickup locations": "Pickup locations",
        "Pieces": "Pieces",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Postal code": "Postal code",
        "Postalcode": "Postalcode",
        "Preparation": "Preparation",
        "Preparation status": "Preparation status",
        "Preparing order": "Preparing order",
        "Previous": "Previous",
        "Price": "Price",
        "Price includes vat": "Price includes vat",
        "Price: High - Low": "Price: High - Low",
        "Price: Low - High": "Price: Low - High",
        "Priority in sort": "Priority in sort",
        "Privacy Statement": "Privacy Statement",
        "Product": "Product",
        "Product as deposit": "Product as deposit",
        "Product as extra": "Product as extra",
        "Product cant be sold": "Product cant be sold",
        "Product too far to deliver": "Product too far to deliver",
        "Product(s) not within limits": "Product(s) not within limits",
        "Products": "Products",
        "Products imported successfully": "Products imported successfully",
        "Quantity": "Quantity",
        "Question": "Question",
        "Questions?": "Questions?",
        "Ready for delivery": "Ready for delivery",
        "Realised by:": "Realised by:",
        "Receive newsletter": "Receive newsletter",
        "Redirect": "Redirect",
        "Redirects": "Redirects",
        "Regards,": "Regards,",
        "Register": "Register",
        "Related product": "Related product",
        "Related products": "Related products",
        "Remark": "Remark",
        "Remark is successfully saved": "Remark is successfully saved",
        "Remark updated": "Remark updated",
        "Required fields": "Required fields",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "results": "results",
        "Revenue": "Revenue",
        "Revenue per month": "Revenue per month",
        "Revenue per week": "Revenue per week",
        "Same address": "Same address",
        "Search:": "Search:",
        "Select": "Select",
        "Select a date": "Select a date",
        "Select a timeslot": "Select a timeslot",
        "Select categorie": "Select categorie",
        "Send": "Send",
        "Send us a mail": "Send us a mail",
        "Sent at": "Sent at",
        "SEO description": "SEO description",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Settings": "Settings",
        "Shop": "Shop",
        "Showing": "Showing",
        "Single product": "Single product",
        "Slug": "Slug",
        "Sory by:": "Sory by:",
        "Star": "Star",
        "Start": "Start",
        "Status": "Status",
        "Street": "Street",
        "Street name": "Street name",
        "Street number": "Street number",
        "Street number addition": "Street number addition",
        "Subject": "Subject",
        "Supply and Delivery": "Supply and Delivery",
        "TAX": "TAX",
        "Terms and conditions": "Terms and conditions",
        "Text": "Text",
        "Thank you for your order!": "Thank you for your order!",
        "The given data was invalid.": "The given data was invalid.",
        "The highest quality": "The highest quality",
        "The minimum order amount of {amount} has not yet been reached": "The minimum order amount of {amount} has not yet been reached",
        "The response is not a streamed response.": "The response is not a streamed response.",
        "The response is not a view.": "The response is not a view.",
        "This action is unauthorized.": "This action is unauthorized.",
        "This month": "This month",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This value will always remain at 1 (piece) or 1000 (grams)": "This value will always remain at 1 (piece) or 1000 (grams)",
        "This week": "This week",
        "Times used": "Times used",
        "Timeslot": "Timeslot",
        "Timeslots": "Timeslots",
        "Title": "Title",
        "to": "to",
        "Toggle navigation": "Toggle navigation",
        "Too Many Requests": "Too Many Requests",
        "Total": "Total",
        "Translated": "Translated",
        "Type": "Type",
        "Uithoorn: delivery costs": "Uithoorn: delivery costs",
        "Uithoorn: Min price for delivery": "Uithoorn: Min price for delivery",
        "Unauthorized": "Unauthorized",
        "Unavailable code": "Unavailable code",
        "Uncombinable": "Uncombinable",
        "Unit type": "Unit type",
        "Unit value": "Unit value",
        "Use `-1` for unlimited usage": "Use `-1` for unlimited usage",
        "User": "User",
        "Users": "Users",
        "Value": "Value",
        "Variant": "Variant",
        "Variant filter": "Variant filter",
        "Variant label": "Variant label",
        "Variants": "Variants",
        "Variants for dropdown": "Variants for dropdown",
        "VAT": "VAT",
        "Vat rate": "Vat rate",
        "Verify Email Address": "Verify Email Address",
        "View": "View",
        "View all products": "View all products",
        "View category": "View category",
        "View product": "View product",
        "Visible": "Visible",
        "We have received your order and are now preparing it for delivery, you can expect your order at:": "We have received your order and are now preparing it for delivery, you can expect your order at:",
        "Website": "Website",
        "Weekday": "Weekday",
        "Whoops!": "Whoops!",
        "Yes": "Yes",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You'll hear from us as soon as possible": "You'll hear from us as soon as possible",
        "Your cart is empty": "Your cart is empty",
        "validation": {
            "accepted": "The {attribute} field must be accepted.",
            "accepted_if": "The {attribute} field must be accepted when {other} is {value}.",
            "active_url": "The {attribute} field must be a valid URL.",
            "after": "The {attribute} field must be a date after {date}.",
            "after_or_equal": "The {attribute} field must be a date after or equal to {date}.",
            "alpha": "The {attribute} field must only contain letters.",
            "alpha_dash": "The {attribute} field must only contain letters, numbers, dashes, and underscores.",
            "alpha_num": "The {attribute} field must only contain letters and numbers.",
            "array": "The {attribute} field must be an array.",
            "ascii": "The {attribute} field must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} field must be a date before {date}.",
            "before_or_equal": "The {attribute} field must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} field must have between {min} and {max} items.",
                "file": "The {attribute} field must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} field must be between {min} and {max}.",
                "string": "The {attribute} field must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "can": "The {attribute} field contains an unauthorized value.",
            "confirmed": "The {attribute} field confirmation does not match.",
            "contains": "The {attribute} field is missing a required value.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} field must be a valid date.",
            "date_equals": "The {attribute} field must be a date equal to {date}.",
            "date_format": "The {attribute} field must match the format {format}.",
            "decimal": "The {attribute} field must have {decimal} decimal places.",
            "declined": "The {attribute} field must be declined.",
            "declined_if": "The {attribute} field must be declined when {other} is {value}.",
            "different": "The {attribute} field and {other} must be different.",
            "digits": "The {attribute} field must be {digits} digits.",
            "digits_between": "The {attribute} field must be between {min} and {max} digits.",
            "dimensions": "The {attribute} field has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} field must not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} field must not start with one of the following: {values}.",
            "email": "The {attribute} field must be a valid email address.",
            "ends_with": "The {attribute} field must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "extensions": "The {attribute} field must have one of the following extensions: {values}.",
            "file": "The {attribute} field must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} field must have more than {value} items.",
                "file": "The {attribute} field must be greater than {value} kilobytes.",
                "numeric": "The {attribute} field must be greater than {value}.",
                "string": "The {attribute} field must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} field must have {value} items or more.",
                "file": "The {attribute} field must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} field must be greater than or equal to {value}.",
                "string": "The {attribute} field must be greater than or equal to {value} characters."
            },
            "hex_color": "The {attribute} field must be a valid hexadecimal color.",
            "image": "The {attribute} field must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field must exist in {other}.",
            "integer": "The {attribute} field must be an integer.",
            "ip": "The {attribute} field must be a valid IP address.",
            "ipv4": "The {attribute} field must be a valid IPv4 address.",
            "ipv6": "The {attribute} field must be a valid IPv6 address.",
            "json": "The {attribute} field must be a valid JSON string.",
            "list": "The {attribute} field must be a list.",
            "lowercase": "The {attribute} field must be lowercase.",
            "lt": {
                "array": "The {attribute} field must have less than {value} items.",
                "file": "The {attribute} field must be less than {value} kilobytes.",
                "numeric": "The {attribute} field must be less than {value}.",
                "string": "The {attribute} field must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} field must not have more than {value} items.",
                "file": "The {attribute} field must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} field must be less than or equal to {value}.",
                "string": "The {attribute} field must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} field must be a valid MAC address.",
            "max": {
                "array": "The {attribute} field must not have more than {max} items.",
                "file": "The {attribute} field must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} field must not be greater than {max}.",
                "string": "The {attribute} field must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} field must not have more than {max} digits.",
            "mimes": "The {attribute} field must be a file of type: {values}.",
            "mimetypes": "The {attribute} field must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} field must have at least {min} items.",
                "file": "The {attribute} field must be at least {min} kilobytes.",
                "numeric": "The {attribute} field must be at least {min}.",
                "string": "The {attribute} field must be at least {min} characters."
            },
            "min_digits": "The {attribute} field must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} field must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} field format is invalid.",
            "numeric": "The {attribute} field must be a number.",
            "password": {
                "letters": "The {attribute} field must contain at least one letter.",
                "mixed": "The {attribute} field must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} field must contain at least one number.",
                "symbols": "The {attribute} field must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "present_if": "The {attribute} field must be present when {other} is {value}.",
            "present_unless": "The {attribute} field must be present unless {other} is {value}.",
            "present_with": "The {attribute} field must be present when {values} is present.",
            "present_with_all": "The {attribute} field must be present when {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_if_accepted": "The {attribute} field is prohibited when {other} is accepted.",
            "prohibited_if_declined": "The {attribute} field is prohibited when {other} is declined.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} field format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_if_declined": "The {attribute} field is required when {other} is declined.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} field must match {other}.",
            "size": {
                "array": "The {attribute} field must contain {size} items.",
                "file": "The {attribute} field must be {size} kilobytes.",
                "numeric": "The {attribute} field must be {size}.",
                "string": "The {attribute} field must be {size} characters."
            },
            "starts_with": "The {attribute} field must start with one of the following: {values}.",
            "string": "The {attribute} field must be a string.",
            "timezone": "The {attribute} field must be a valid timezone.",
            "ulid": "The {attribute} field must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} field must be uppercase.",
            "url": "The {attribute} field must be a valid URL.",
            "uuid": "The {attribute} field must be a valid UUID."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The provided password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "passwords": {
            "reset": "Your password has been reset.",
            "sent": "We have emailed your password reset link.",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "nl": {
        " homepage": "homepage",
        " or visit one of the pages below.": "of bezoek een van de onderstaande pagina's.",
        " © De Vers Specialist - Made by": "© De Vers Specialist - Gerealiseerd door",
        " © De Vers Specialist - Made by STRIXI": "© De Vers Specialist - Gerealiseerd door STRIXI",
        "'Meat core temperature": "Kerntemperatuur Vlees",
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmelding)|(en {count} andere foutmeldingen)|(en {count} andere foutmeldingen)",
        "*Verplichte velden": "*Verplichte velden",
        "100% fresh guarantee": "100% vers garantie",
        "100% fresh guaranteed": "100% vers garantie",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "{1} {pieces} piece|[2,*] {pieces} pieces": "{1} {pieces} stuk|[2,*] {pieces} stuks",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "{weight} g": "{weight} g",
        "{weight} kg": "{weight} kg",
        "<strong>Sorry, we can\\'t find this page anymore!</strong><br/><br/> We\\'ve done our best, but it seems that this page no longer exists or may have moved. Of course you can always go to the {link} or visit one of the pages below.": "<strong>Sorry, we kunnen deze pagina niet meer vinden! </strong><br/><br/> We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet meer bestaat of verplaatst is. U kunt natuurlijk altijd naar de {link} gaan of een van de onderstaande pagina's bezoeken.",
        "A decryption key is required.": "Een decryptiesleutel is verplicht.",
        "A fresh verification link has been sent to your email address.": "Er is een nieuwe verificatielink naar je e-mailadres verstuurd.",
        "A new verification link has been sent to the email address you provided during registration.": "Er is een nieuwe verificatielink verstuurd naar het e-mailadres dat je ingegeven hebt tijdens de registratie.",
        "About de vers specialist": "Over de vers specialist",
        "About DE VERSSPECIALIST": "Over DE VERSSPECIALIST",
        "About Us": "Over ons",
        "About us": "Over ons",
        "about us": "over ons",
        "About us | De Vers Specialist": "Over ons | De Vers Specialist",
        "Accept Invitation": "Uitnodiging accepteren",
        "Active": "Actief",
        "Active until": "Actief tot",
        "Add": "Toevoegen",
        "Add a new team member to your team, allowing them to collaborate with you.": "Voeg een nieuw teamlid toe aan je team, zodat ze met je kunnen samenwerken.",
        "Add additional security to your account using two factor authentication.": "Voeg extra beveiliging toe aan je account met twee-factor-authenticatie.",
        "Add coupon": "Kortingscode toevoegen",
        "Add page block": "Voeg pagina blok toe",
        "Add Team Member": "Teamlid toevoegen",
        "Added to your cart!": "Toegevoegd aan uw winkelwagen",
        "Added.": "Toegevoegd.",
        "Addition": "Toevoeging",
        "Addition (Optional)": "Toevoeging (Optioneel)",
        "Address": "Adres",
        "Address not valid": "Adres niet geldig",
        "Administrator": "Beheerder",
        "Administrator users can perform any action.": "Beheerders kunnen elke actie uitvoeren.",
        "all": "Alle",
        "All categories": "Alle categorieen",
        "All of the people that are part of this team.": "Alle mensen die deel uitmaken van dit team.",
        "All related products": "Alle gerelateerde producten",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "Allergens": "Allergenen",
        "Already registered?": "Al geregistreerd?",
        "Alt text": "Alt tekst",
        "Always show banner": "Laat banner altijd zien",
        "Amount": "Aantal",
        "Amount of products:": "Aantal producten:",
        "Answer": "Antwoord",
        "answer": "antwoord",
        "Any questions about your order? Contact us or take a look at the <a href=\"https://deversspecialist.nl/veelgestelde-vragen\">frequently asked questions</a>": "Nog vragen over je bestelling? Neem contact met ons op of neem een kijkje bij de <a href=\"https://deversspecialist.nl/veelgestelde-vragen\">veelgestelde vragen</a>",
        "API Token": "API-token",
        "API Token Permissions": "API-tokenrechten",
        "API Tokens": "API-tokens",
        "API tokens allow third-party services to authenticate with our application on your behalf.": "Met API-tokens kunnen andere services zich als jou authenticeren in onze applicatie.",
        "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.": "Weet je zeker dat je dit team wilt verwijderen? Zodra een team is verwijderd, worden alle bronnen en gegevens ook permanent verwijderd.",
        "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.": "Weet je zeker dat je je account permanent wilt verwijderen? Als je account wordt verwijderd, worden alle gekoppelde bestanden en gegevens ook permanent verwijderd. Voer alsjeblieft je wachtwoord in, om te bevestigen dat je je account permanent wilt verwijderen.",
        "Are you sure you would like to delete this API token?": "Weet je zeker dat je deze API-token wilt verwijderen?",
        "Are you sure you would like to leave this team?": "Weet je zeker dat je dit team wilt verlaten?",
        "Are you sure you would like to remove this person from the team?": "Weet je zeker dat je deze persoon uit het team wilt verwijderen?",
        "Back": "Terug",
        "Back to Home": "Terug naar Hoofdpagina",
        "Back to home": "Terug naar home",
        "Back to productoverview": "Terug naar productoverzicht",
        "Before proceeding, please check your email for a verification link.": "Om verder te gaan, check je e-mail voor een verificatielink.",
        "Billing address": "Factuuradres",
        "Block day": "Blokkeer dag",
        "Browser Sessions": "Browsersessies",
        "Cancel": "Annuleren",
        "Cancelled": "Geannuleerd",
        "Car": "Auto",
        "Cart": "Winkelwagen",
        "Categories": "Categorieën",
        "Category": "Categorie",
        "Category schema": "Categorie schema",
        "Category schemas": "Categorie schemas",
        "celery": "Selderij",
        "Checkmark": "Vinkje",
        "Checkout": "Afrekenen",
        "Checkout | De Vers Specialist": "Afrekenen | De Vers Specialist",
        "Chicken icon": "Kip icoon",
        "City": "Stad",
        "City*": "Stad*",
        "click here to request another": "vraag hier een andere aan",
        "Clock": "Klok",
        "Close": "Sluit",
        "Code": "Code",
        "Column order": "Kolom volgorde",
        "Company details": "Bedrijfsdetails",
        "Company name": "Bedrijfsnaam",
        "Company Name (Optional)": "Bedrijfsnaam (Optioneel)",
        "Completed": "Voltooid",
        "completed": "Voltooid",
        "Confirm": "Bevestig",
        "Confirm Password": "Bevestig wachtwoord",
        "Confirmation {source}": "Bevestiging {source}",
        "Contact": "Contact",
        "Contact De Vers Specialist": "Contacteer De Vers Specialist",
        "contact form": "contactformulier",
        "Contact | De Vers Specialist": "Als u meerdere produkten nodig hebt dan bent u bij ons dus voordelig uit. Zo kunt u besparen op de dagelijkse kosten. In onze zelfbedieningswinkel kunt u zelf uw keuze maken uit de verschillende produkten. Heeft u speciale wensen, dan kunt u deze kenbaar maken bij onze bedieningstoonbank.",
        "Contacts": "Contacten",
        "cookie-consent:{texts}.agree": "Akkoord",
        "cookie-consent:{texts}.message": "We gebruiken de nodige cookies om de website zo goed mogelijk te laten werken. Ga akkoord met het gebruik van additionele cookies voor de beste ervaring. Bekijk voor meer informatie onze {privacyStatement}.",
        "Countries": "Landen",
        "Country*": "Land*",
        "Coupon": "Kortingscode",
        "Coupon (Optional)": "Kortingscode (Optioneel)",
        "Coupon added": "Kortingscode toegevoegd",
        "Coupon after order": "Coupon na bestelling",
        "Coupon applied": "Coupon toegepast",
        "Coupon can no longer be used": "Coupon kan niet meer gebruikt worden",
        "Coupon code": "Kortingscode",
        "Coupon deleted": "Kortingscode verwijderd",
        "Coupon invalid": "Kortingscode niet geldig",
        "Coupon is no available anymore": "Kortingscode is niet meer geldig",
        "Coupon not found": "Coupon niet gevonden",
        "Coupon successfully added": "Kortingscode succesvol toegevoegd",
        "Coupon successfully deleted": "Kortingscode succesvol verwijderd",
        "Coupon type": "Coupon type",
        "Coupon value fixed": "Couponwaarde vast",
        "Coupon value percentage": "Couponwaarde percentage",
        "Coupons": "Kortingscodes",
        "Create": "Aanmaken",
        "Create a new team to collaborate with others on projects.": "Maak een nieuw team aan om met anderen aan projecten samen te werken.",
        "Create Account": "Account aanmaken",
        "Create API Token": "Maak een API-token",
        "Create New Team": "Maak nieuw team aan",
        "Create Team": "Maak team aan",
        "Created at": "Gemaakt op",
        "Created.": "Aangemaakt.",
        "CSV file with `id` and `price` columns": "CSV bestand met `id` en `prijs` kolommen",
        "Current Password": "Huidig wachtwoord",
        "Custom": "Aangepast",
        "Customer": "Klant",
        "Customers": "Klanten",
        "Cutting meat": "Vlees snijden",
        "Dashboard": "Dashboard",
        "Date": "Datum",
        "Day": "Dag",
        "De Vers Specialist delivers to your address!": "De Vers Specialist levert aan uw adres!",
        "De Vers Specialist has been the meat address for fresh meat at low prices for over 30 years. With us, the entire range has fixed, low prices. So not only the weekly offer is competitively priced, but the entire range. <br/> <br/> So if you need several products, you can benefit from us. This way you can save on daily costs. In our self-service shop you can make your own choice from the various products. If you have special wishes, you can request these at our service counter. <br/><br/> We make fresh products every day in our own sausage factory and smokehouse. We have hot worst fresh from the kettle every day. Where else do you find that? We also have many smoked products such as smoked chicken legs, chicken breast, ham slices, spare ribs, lean and fatty bacon. <br/><br/> In our sausage factory we make ox sausage, (coarse) smoked sausage, grill sausage, horse sausage and bratwurst every day. We also make fresh filet americain every day.": "De Vers Specialist is al ruim 30 jaar het vertrouwde adres voor vers vlees tegen lage prijzen. Bij ons heeft het hele assortiment vaste, lage prijzen. Dus niet alleen de weekaanbieding is scherp geprijsd, maar het hele assortiment.",
        "Dear {name}": "Beste {name}",
        "Default": "Standaard",
        "default": "standaard",
        "Delete": "Verwijder",
        "Delete Account": "Account Verwijderen",
        "Delete API Token": "API-token Verwijderen",
        "Delete product": "Verwijder product",
        "Delete Team": "Team Verwijderen",
        "Deleted at": "Verwijderd op",
        "Deleted from your cart!": "Verwijderd uit uw winkelwagen!",
        "Deliver": "Bezorgen",
        "Delivery": "Bezorgen",
        "delivery": "levering",
        "Delivery Cost": "Bezorgkosten",
        "Delivery cost": "Bezorgkosten",
        "Delivery costs": "Bezorgkosten",
        "Delivery date": "Leveringsdatum",
        "Delivery date is required": "Bezorgdatum is verplicht.",
        "Delivery drivers": "Bezorgers (auto)",
        "Delivery location": "Bezorglocatie",
        "Delivery method": "Bezorgtype",
        "Delivery time": "Bezorgtijd",
        "Delivery:": "Bezorgen",
        "Description": "Beschrijving",
        "description": "omschrijving",
        "Disable": "Schakel uit",
        "Disabling a coupon will prevent it from being used": "Coupon kan hiermee niet worden gebruikt",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Discount code": "Kortingscode",
        "Discount code copied": "Kortingscode gekopieerd",
        "Discount code could not be copied": "Kortingscode kan niet worden gekopieerd",
        "Discounted price": "Kortingsprijs",
        "Display type": "Weergavetype",
        "Do you have any questions or can't find what you're looking for?": "Heeft u vragen of kunt u niet vinden wat u zoekt?",
        "Do you have any questions or can\\'t find what you\\'re looking for?": "Heeft u vragen of kunt u niet vinden wat u zoekt?",
        "Done.": "Klaar.",
        "Download packing slip": "Download pakbon",
        "E-Mail Address": "E-Mail Address",
        "E-mail*": "E-mail*",
        "E.g. Chicken, Turky, Dinners": "Bijv. Kip, Kalkoen, Maaltijden",
        "Ecommerce settings": "e-commerce instellingen",
        "Editor": "Redacteur",
        "Editor users have the ability to read, create, and update.": "Redacteurs hebben de bevoegdheid om te lezen, te creëren en te bewerken.",
        "egg": "Ei",
        "Email": "E-mailadres",
        "Email address": "E-mailadres",
        "Email Password Reset Link": "Verstuur link",
        "Enable": "Schakel in",
        "Encrypted environment file already exists.": "Versleuteld environment-bestand bestaat al.",
        "Encrypted environment file not found.": "Versleuteld environment-bestand niet gevonden.",
        "End": "Einde",
        "Ensure your account is using a long, random password to stay secure.": "Zorg ervoor dat je account een lang, willekeurig wachtwoord gebruikt om veilig te blijven.",
        "Enter": "Ga naar",
        "Enter your zip code and house number below and check whether we can deliver your order at home!": "Vul hieronder uw postcode en huisnummer in en kijk of wij uw bestelling thuis kunnen bezorgen!",
        "Environment file already exists.": "Environment-bestand bestaat al.",
        "Environment file not found.": "Environment-bestand niet gevonden.",
        "errors": "fouten",
        "External ID": "Externe ID",
        "Extra product": "Extra product",
        "Extra products": "Extra producten",
        "Extra's": "Extra's",
        "Failed": "Gefaald",
        "FAQ": "Veelgestelde vragen",
        "FAQ | De Vers Specialist": "In onze eigen worstmakerij en rokerij maken we dagelijks verse producten. We hebben elke dag warme worst vers uit de ketel. Waar vind je dat nog? Ook hebben wij tal van gerookte produkten zoals gerookte kippenbouten, kipfilet, hamschijven, spare-ribs, mager en vet spek.",
        "Fast home delivery": "Snel thuisbezorgd",
        "filament-breezy:{default}.login.forgot_password_link": "Wachtwoord vergeten?",
        "filament-breezy:{default}.or": "Of",
        "filament-breezy:{default}.registration.heading": "Nieuwe account aanmaken",
        "filament-table-repeater:{components}.repeater.empty.label": "Nog geen items toegevoegd",
        "filament:{login}.buttons.submit.label": "Inloggen",
        "filament:{login}.heading": "Inloggen op je account",
        "Fill in another address": "Vul een ander adres in",
        "Finish enabling two factor authentication.": "Finish enabling two factor authentication.",
        "First name": "Voornaam",
        "First Name*": "Voornaam*",
        "Firstly add your address": "Voer eerst je adres in",
        "Fixed": "Vast",
        "For example: Bavette, tenderloin, rack of lamb": "Bijvoorbeeld: Bavette, ossenhaas, lamsrack",
        "For your security, please confirm your password to continue.": "Bevestig voor de zekerheid je wachtwoord om door te gaan.",
        "Forbidden": "Geen toegang",
        "Forget something?": "Iets vergeten?",
        "Forgot Your Password?": "Wachtwoord Vergeten?",
        "Forgot your password?": "Wachtwoord vergeten?",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Wachtwoord vergeten? Geen probleem. Geef hier je e-mailadres in en we sturen je een link via mail waarmee je een nieuw wachtwoord kan instellen.",
        "free delivery if you add {remaining} to your cart": "gratis levering als u {remaining} toevoegt aan uw winkelwagen",
        "Free delivery threshold": "Drempel voor gratis bezorging",
        "Frequently asked question": "Veelgestelde vraag",
        "Frequently Asked Questions": "Veelgestelde vragen",
        "Frequently asked questions": "Veelgestelde vragen",
        "Frequintly asked questions": "Veelgestelde vragen",
        "full width": "Volle breedte",
        "full_width": "Volle breedte",
        "gluten": "Gluten",
        "gmo": "GMO",
        "Go Home": "Terug naar de Hoofdpagina",
        "Go to page {page}": "Ga naar pagina {page}",
        "Google Maps URL": "Google Maps URL",
        "gpt-trix-editor:{gpt}-trix-editor.notification.invalid_action": "Ongeldige actie",
        "Grams": "Gram",
        "Great! You have accepted the invitation to join the {team} team.": "Mooizo! Je hebt de uitnodiging om deel te nemen aan {team} geaccepteerd.",
        "Handler": "Verwerker",
        "Header": "Header",
        "Heading": "Heading",
        "heading_1": "Heading 1",
        "heading_2": "Heading 2",
        "heading_3": "Heading 3",
        "heading_4": "Heading 4",
        "heading_5": "Heading 5",
        "heading_6": "Heading 6",
        "Hello!": "Hallo!",
        "Hero": "Hero",
        "Hero image": "Hero afbeelding",
        "Hide on header": "Verbergen in de header",
        "Hits": "Hits",
        "Home": "Hoofdpagina",
        "Home | De Vers Specialist": "Hoofdpagina | De Vers Specialist",
        "homepage": "homepage",
        "Hospitality Industry": "Horeca",
        "Hospitality industry": "Horeca",
        "Hospitality industry | De Vers Specialist": "Horeca | De Vers Specialist",
        "House number": "Huisnummer",
        "House number*": "Huisnummer*",
        "I agree to the {terms_of_service} and {privacy_policy}": "Ik ga akkoord met de {terms_of_service} en de {privacy_policy}",
        "I agree with the terms and conditions and have checked my order and details *": "Ik ga akkoord met de voorwaarden en heb mijn bestelling en gegevens gecontroleerd *.",
        "I would like to receive a newsletter from De Vers Specialist.": "Ik ontvang graag een nieuwsbrief van De Vers Specialist.",
        "Icon": "Icoon",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.": "Indien nodig, kunt u uitloggen bij al uw andere browsersessies op al uw apparaten. Sommige van uw recente sessies staan hieronder vermeld; deze lijst is echter mogelijk niet volledig. Als u denkt dat uw account is gecompromitteerd, moet u ook uw wachtwoord bij te werken.",
        "If this was a mistake, you can try again using the button below": "Als dit een vergissing was, kunt u het opnieuw proberen via de onderstaande knop",
        "If you already have an account, you may accept this invitation by clicking the button below:": "Als je al een account hebt, kan je deze uitnodiging accepteren door op onderstaande knop te klikken:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not expect to receive an invitation to this team, you may discard this email.": "Als je geen uitnodiging voor dit team verwachtte, mag je deze mail negeren.",
        "If you did not receive the email": "Als je de e-mail niet hebt ontvangen",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:": "Als je nog geen account hebt, kan je er een aanmaken door op onderstaande knop te klikken. Na het aanmaken van je account kan je op de uitnodiging in deze mail klikken om die te accepteren:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hieronder\nin je webbrowser:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you’re having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "Image": "Afbeelding",
        "Image of a meat store": "Beeld van een vleeswinkel",
        "Images": "Afbeeldingen",
        "Import": "Import",
        "Impression image": "Impressie beeld",
        "Including": "waaronder",
        "including": "waaronder",
        "Initial": "Initieel",
        "initial": "Initieel",
        "Interval": "Interval",
        "Invalid filename.": "Ongeldige bestandsnaam.",
        "Invalid JSON was returned from the route.": "Er is ongeldige JSON teruggekomen van de route.",
        "Invoice address (Optional)": "Factuuradres (Optioneel)",
        "Invoice number:": "Factuurnummer:",
        "Keeps for at least 7 days and suitable for freezing at home": "Minimaal 7 dagen houdbaar en geschikt om thuis in te vriezen",
        "lactose": "Lactose",
        "Language dropdown icon": "Taal dropdown-pictogram",
        "Last active": "Laatst actief",
        "Last name": "Achternaam",
        "Last Name*": "Achternaam*",
        "Last used": "Laatst gebruikt",
        "Latest orders": "Laatste bestellingen",
        "Leave": "Verlaat",
        "Leave empty for no end date": "Laat leeg voor geen einddatum",
        "Leave empty to generate a random code. Not editable after creation.": "Laat leeg om een willekeurige code te genereren die niet bewerkbaar is na aanmaken",
        "Leave empty to use parent details": "Laat leeg om de gegevens van de hoofdproduct te gebruiken",
        "Leave Team": "Team Verlaten",
        "length": "lengte",
        "Level": "Level",
        "List of categories": "Lijst met categorieen",
        "List of products": "Lijst met producten",
        "List type": "Lijst type",
        "Location": "Locatie",
        "Locations": "Locaties",
        "Log in": "Inloggen",
        "Log Out": "Uitloggen",
        "Log Out Other Browser Sessions": "Uitloggen bij alle sessies",
        "Login": "Inloggen",
        "Logout": "Uitloggen",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "Make your decision:": "Maak uw keuze:",
        "Manage Account": "Accountbeheer",
        "Manage and log out your active sessions on other browsers and devices.": "Beheer je actieve sessies op andere browsers en andere apparaten.",
        "Manage API Tokens": "Beheer API-tokens",
        "Manage Role": "Beheer Rol",
        "Manage Team": "Beheer Team",
        "Max delivery distance in KM": "Max leverafstand in KM",
        "Max in order": "Maximaal aantal in bestelling",
        "Max kilometer": "Maximaal aantal kilometer",
        "Max order": "Maximale bestelling",
        "Max orders": "Maximaal aantal bestellingen",
        "Max orders per day": "Max bestellingen per dag",
        "Max users": "Maximaal keer gebruik",
        "meat": "Vlees",
        "Meats": "Vleeswaren",
        "Menu": "Menu",
        "Menu items": "Menu items",
        "Menus": "Menus",
        "Message": "Bericht",
        "Message sent": "Bericht verzonden",
        "Middle name": "Middelnaam",
        "Mijdrecht: delivery costs": "Mijdrecht: verzendkosten",
        "Mijdrecht: min price for delivery": "Mijdrecht: min prijs voor levering",
        "Min in order": "Minimaal aantal in bestelling",
        "Min order": "Minimale bestelling",
        "Min preparation": "Minimaal bereidingtijd",
        "Min preparation in hours": "Min voorbereiding in uren",
        "Min price for delivery": "Minimale prijs voor bezorging",
        "Minimum preparation days": "Minimaal aantal bereiding dagen",
        "molluscs": "Schelp-dieren",
        "Monday - Saturday: 08{00} am - 18{00} pm": "Maandan t/m Zaterdag: 08{00} - 18{00}",
        "Month": "Maand",
        "More information": "Meer informatie",
        "Most sold": "Best verkocht",
        "mustard": "Mosterd",
        "Name": "Naam",
        "name": "naam",
        "Name*": "Naam*",
        "New orders": "Nieuwe bestellingen",
        "New Password": "Nieuw wachtwoord",
        "New URL": "Nieuwe URL",
        "Next": "Volgende",
        "No": "Nee",
        "No frequently asked questions found": "Geen veelgestelde vragen gevonden",
        "No products found": "Geen producten gevonden",
        "No products in shopping cart yet": "Nog geen producten in winkelwagen",
        "No products in your shopping cart yet": "Nog geen producten in je winkelwagen",
        "Not available because of product(s):": "Niet beschikbaar door product(en):",
        "Not available in this combination": "Niet beschikbaar met deze combinatie",
        "Not available with product(s):": "Niet beschikbaar met product(en):",
        "Not editable after creation.": "Niet meer te bewerken na aanmaak",
        "Not Found": "Niet gevonden",
        "Not visible in shop but orderable in combination with other products": "Niet zichtbaar in de winkel maar wel bestelbaar als extra bij andere producten",
        "not yet reached.": "nog niet bereikt",
        "nuts": "Noten",
        "of": "van",
        "Oh no": "Oh nee",
        "Old URL": "Oude URL",
        "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.": "Zodra een team is verwijderd, worden alle bronnen en gegevens permanent verwijderd. Download voordat je dit team verwijdert alle gegevens of informatie over dit team die je wilt behouden.",
        "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.": "Als je account wordt verwijderd, worden alle gekoppelde bestanden en gegevens ook permanent verwijderd. Sla alsjeblieft alle data op die je wilt behouden, voordat je je account verwijderd.",
        "Oops": "Oeps",
        "Open menu": "Open menu",
        "Opening hours": "Openingstijden",
        "Opening hours - Dutch": "Openingstijden - Nederlands",
        "Opening hours - English": "Openginstijden - Engels",
        "Openings hours": "Openingstijden",
        "Order": "Bestelling",
        "Order & pickup": "Bestellen & afhalen",
        "Order arrived": "Bestelling bezorgd",
        "Order column": "Volgorde",
        "Order Confirmation": "Bestelling bevestiging",
        "Order date": "Datum bestelling",
        "Order date:": "Besteldatum:",
        "Order datum:": "Besteldatum",
        "Order is delivered fresh and chilled": "Bestelling wordt vers en gekoeld geleverd",
        "Order now": "Bestellen",
        "Order number": "Bestelnummer",
        "Order number:": "Bestelnummer:",
        "Order online": "Bestel online",
        "Order Overview": "Besteloverzicht",
        "Order received": "Bestelling ontvangen",
        "Order Received | De Vers Specialist": "Bestelling ontvangen | De Vers Specialist",
        "Orderable and visible": "Bestelbaar en zichtbaar",
        "Ordered before {time}, delivered tomorrow!": "Voor {time} besteld, morgen in huis!",
        "Ordernumber:": "Bestelnummer",
        "Orders": "Bestellingen",
        "Overview": "Overzicht",
        "Overview order": "Overzicht bestelling",
        "Page": "Pagina",
        "Page blocks": "Paginablokken",
        "Page Expired": "Pagina niet meer geldig",
        "Page not found": "Pagina niet gevonden",
        "Page SEO": "Pagina SEO",
        "Page setting": "Pagina instelling",
        "Page settings": "Pagina instellingen",
        "Pages": "Paginas",
        "Pagination Navigation": "Paginanavigatie",
        "Paragraph": "Paragraaf",
        "Parent category": "Hoofd categorie",
        "Password": "Wachtwoord",
        "Pay": "Betaal",
        "Payment": "Betaling",
        "Payment methods": "Betaalmethoden",
        "Payment Required": "Betaling vereist",
        "peanuts": "Pinda",
        "Pending Team Invitations": "Openstaande Team uitnodigingen",
        "Per interval": "Per interval",
        "Percentage": "Percentage",
        "Permanent": "Permanent",
        "Permanently delete this team.": "Verwijder dit team definitief.",
        "Permanently delete your account.": "Verwijder je account permanent.",
        "Permissions": "Rechten",
        "Phone number": "Telefoonnummer",
        "Photo": "Foto",
        "Pick up at De Vers Specialist": "Afhalen bij De Vers Specialist",
        "Pick up:": "Afhalen",
        "Pickup": "Afhalen",
        "pickup": "afhalen",
        "Pickup date / Delivery date": "Afhaaldatum / Leveringsdatum",
        "Pickup location": "Afhaallocatie",
        "Pickup locations": "Ophaallocaties",
        "Pieces": "Stuk",
        "pieces": "Per stuk",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Bevestig de toegang tot je account door een van je noodherstelcodes in te voeren.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Bevestig de toegang tot je account door de authenticatiecode in te voeren die door je authenticator-applicatie is aangemaakt.",
        "Please copy your new API token. For your security, it won't be shown again.": "Kopieer je nieuwe API-token. Voor de veiligheid zal het niet opnieuw getoond worden.",
        "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.": "Voer uw wachtwoord in om te bevestigen dat u zich wilt afmelden bij uw andere browsersessies op al uw apparaten.",
        "Please provide the email address of the person you would like to add to this team.": "Geef het e-mailadres op van de persoon die je aan dit team wilt toevoegen.",
        "pork": "Varken",
        "Postal code": "Postcode",
        "Postalcode": "Postcode",
        "Preparation": "Voorbereiding",
        "preparation": "voorbereiding",
        "Preparation status": "Bereidingsstatus",
        "PreparationStatus": "Voorbereidingsstatus",
        "Prepare order": "Bestelling voorbereiden",
        "Preparing": "Wordt bereid",
        "Preparing order": "Bestelling voorbereiden",
        "Previous": "Vorige",
        "Price": "Prijs",
        "Price includes vat": "Prijs bevat BTW",
        "Price: High - Low": "Prijs Hoog - Laag",
        "Price: Low - High": "Prijs Laag - Hoog",
        "Priority in sort": "Prioriteit in sortering",
        "Privacy Policy": "Privacybeleid",
        "Privacy Statement": "Privacyverklaring",
        "privacy statement": "privacyverklaring",
        "Product": "Product",
        "Product as deposit": "Product als borg",
        "Product as extra": "Product als extra",
        "Product cant be sold": "Product kan niet worden verkocht",
        "Product description": "Productomschrijving",
        "Product not within limits": "Product niet binnen limieten",
        "Product number": "Productnummer",
        "Product too far to deliver": "Product te ver om te bezorgen",
        "Product(s) not within limits": "Product(en) niet binnen limieten",
        "Products": "Producten",
        "Products imported successfully": "Producten succesvol geimporteerd",
        "Profile": "Profiel",
        "Profile Information": "Profiel Informatie",
        "Quantity": "Aantal",
        "Question": "Vraag",
        "question": "vraag",
        "Questions?": "Vragen?",
        "Ready": "Klaar",
        "Ready for delivery": "Klaar voor levering",
        "Realised by:": "Gerealiseerd door:",
        "Receive newsletter": "Ontvang nieuwsbrief",
        "Received order: {order}": "Bevestiging bestelling {order}",
        "Recovery Code": "Herstelcode",
        "Redirect": "omleiden",
        "Redirects": "Omleidingen",
        "Refund started": "Terugbetaling gestart",
        "Refunded": "Terugbetaald",
        "Regards": "Met vriendelijke groet",
        "Regards,": "Met vriendelijke groet,",
        "Regenerate Recovery Codes": "Herstelcodes Opnieuw Genereren",
        "Register": "Registreren",
        "Related product": "Gerelateerde producten",
        "Related products": "Gerelateerde producten",
        "Remark": "Opmerking",
        "Remark is successfully saved": "Opmerking succesvol opgeslagen",
        "Remark updated": "Opmerking bijgewerkt",
        "Remember Me": "Onthoud mij",
        "Remember me": "Onthouden",
        "Remove": "Verwijder",
        "Remove Photo": "Foto Verwijderen",
        "Remove Team Member": "Teamlid Verwijderen",
        "Removed from your cart!": "Verwijderd uit uw winkelwagen!",
        "Required fields": "*Verplichte velden",
        "Resend Verification Email": "Verificatie-e-mail opnieuw versturen",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Notificatie wachtwoordherstel",
        "results": "resultaten",
        "Retry payment": "Betaling opnieuw proberen",
        "Revenue": "Omzet",
        "Revenue per month": "Omzet per maand",
        "Revenue per week": "Omzet per week",
        "Role": "Rol",
        "Roles": "Rollen",
        "Sale": "Actie",
        "Same address": "Hetzelfde adres",
        "Same as invoice address": "Hetzelfde als het factuuradres",
        "Save": "Opslaan",
        "Saved.": "Opgeslagen.",
        "Search:": "Zoeken:",
        "See all products": "Bekijk alle producten",
        "Select": "Selecteer",
        "Select a country": "Selecteer een land",
        "Select a date": "Selecteer een datum",
        "Select A New Photo": "Selecteer Een Nieuwe Foto",
        "Select a timeslot": "Selecteer een tijdslot",
        "Select categorie": "Selecteer categorie",
        "Send": "Verzenden",
        "Send Password Reset Link": "Verstuur link voor wachtwoordherstel",
        "Send us a mail": "Verstuur ons een mail",
        "Send us an e-mail": "Stuur ons een e-mail",
        "Sent at": "Verzonden op",
        "SEO description": "SEO beschrijving",
        "SEO title": "SEO titel",
        "Server Error": "Serverfout",
        "Service Unavailable": "Website onbeschikbaar",
        "Settings": "Instellingen",
        "Setup Key": "Setup Key",
        "shellfish": "Schaal-dieren",
        "Shipping address": "Verzendadres",
        "Shipping address (Optional)": "Verzendadres (Optioneel)",
        "Shop": "Winkel",
        "Show Recovery Codes": "Toon herstelcodes",
        "Showing": "Toont",
        "Single product": "Enkel product",
        "Slug": "Permalink",
        "Sorry, orders that exceed € 2000,- need to order via email or phone": "Sorry, bestellingen van meer dan € 2000,- moeten via e-mail of telefoon besteld worden.",
        "Sorry, we can't find this page anymore!": "Sorry, we kunnen deze pagina niet meer vinden!",
        "Sory by:": "Sorteren op:",
        "soy": "Soja",
        "Star": "Ster",
        "Start": "Start",
        "Status": "Status",
        "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.": "Bewaar deze herstelcodes in een beveiligde wachtwoordbeheerder. Ze kunnen worden gebruikt om de toegang tot je account te herstellen als je twee-factor-authenticatieapparaat verloren is gegaan.",
        "Street": "Straat",
        "Street name": "Straatnaam",
        "Street number": "Straatnummer",
        "Street number addition": "Straatnummer toevoeging",
        "Street Number*": "Straatnummer*",
        "Street*": "Straat*",
        "Subject": "Onderwerk",
        "Subtotal": "Subtotaal",
        "sugar": "Suiker",
        "Sunday: closed": "Zondag: gesloten",
        "Supply and Delivery": "Levering en bezorging",
        "Switch Teams": "Wissel Van Team",
        "Synchronisation": "Synchronisatie",
        "TAX": "BTW",
        "Tax": "Btw",
        "Team Details": "Teamdetails",
        "Team Invitation": "Team uitnodiging",
        "Team Members": "Teamleden",
        "Team Name": "Teamnaam",
        "Team Owner": "Team Eigenaar",
        "Team Settings": "Team Instellingen",
        "Telefoonnummer*": "Telefoonnummer*",
        "Terms and conditions": "Algemene Voorwaarden",
        "Terms of Service": "Algemene voorwaarden",
        "Text": "Tekst",
        "Thank you for your message!": "Bedankt voor uw bericht!",
        "Thank you for your order at De Vers Specialist! We will now process your order and will notify you as soon as your order is ready for {deliveryMethod}.": "Bedankt voor je bestelling bij De Vers Specialist! We gaan je bestelling nu verwerken en zullen jou op de hoogte stellen zodra je bestelling klaar is voor {deliveryMethod}.",
        "Thank you for your order!": "Bedankt voor je bestelling!",
        "Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.": "Bedankt voor je registratie! Wil je voordat je begint je e-mailadres verifiëren door op de link te klikken die we je zojuist via mail hebben verstuurd? Als je de e-mail niet hebt ontvangen, sturen we je graag een nieuwe.",
        "The {attribute} must be a valid role.": "Het {attribute} moet een geldige rol zijn.",
        "The {attribute} must be at least {length} characters and contain at least one number.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "De {attribute} moet minstens {length} karakters zijn en minstens één speciaal teken en één nummer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minstens één speciaal karakter bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "Het {attribute} moet minstens {length} tekens lang zijn en minstens één hoofdletter en één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één hoofdletter en één speciaal teken bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "Het {attribute} moet minstens {length} tekens lang zijn en minstens één hoofdletter, één cijfer en één speciaal teken bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één hoofdletter bevatten.",
        "The {attribute} must be at least {length} characters.": "Het {attribute} moet minstens {length} karakters lang zijn.",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The catering entrepreneurs also know how to find their way to De Vers Specialist. When it comes to fresh meat at low prices, then you have come to the right place at our butcher shop. The meat can also be delivered to you. We do not charge any costs for this. Separate price agreements are made with each catering customer, depending on the quantities that are purchased. So you can take advantage of fresh meat at fixed, low prices with free delivery.": "Ook de horeca-ondernemers weten de weg naar De Vers Specialist te vinden. Als het gaat om vers vlees tegen lage prijzen, dan bent u bij onze slagerij aan het juiste adres. Ook kan het vlees bij u bezorgd worden. Hiervoor brengen wij geen kosten in rekening. Met elke horeca-klant worden aparte prijsafspraken gemaakt, afhankelijk van de hoeveelheden die worden afgenomen. U kunt dus profiteren van vers vlees tegen vaste, lage prijzen met gratis bezorging.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The highest quality": "De hoogste kwaliteit",
        "The minimum order amount of": "Het minimale bestelbedrag van",
        "The minimum order amount of {amount} has not yet been reached": "Het minimum bestelbedrag van {amount} is nog niet bereikt",
        "The password is incorrect.": "Het wachtwoord is incorrect.",
        "The provided password does not match your current password.": "Het opgegeven wachtwoord komt niet overeen met je huidige wachtwoord.",
        "The provided password was incorrect.": "Het opgegeven wachtwoord is onjuist.",
        "The provided two factor authentication code was invalid.": "De opgegeven twee-factor-authenticatiecode was ongeldig.",
        "The response is not a streamed response.": "De respons is niet gestreamd.",
        "The response is not a view.": "De respons is geen view.",
        "The team's name and owner information.": "De naam van het team en de informatie over de eigenaar.",
        "Then place a new order or contact us!": "Plaats dan een nieuwe bestelling of neem contact met ons op!",
        "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.": "Deze personen hebben een uitnodiging ontvangen om lid te worden van je team. Ze kunnen deelnemen door de uitnodiging te accepteren.",
        "This action is unauthorized.": "Deze actie is niet toegestaan.",
        "This device": "Dit apparaat",
        "This is a secure area of the application. Please confirm your password before continuing.": "Dit is een beveiligd gedeelte van de applicatie. Bevestig je wachtwoord voordat je doorgaat.",
        "This month": "Deze maand",
        "This password does not match our records.": "Het wachtwoord is onbekend.",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "This user already belongs to the team.": "Deze gebruiker is al toegewezen aan het team.",
        "This user has already been invited to the team.": "Deze gebruiker is al uitgenodigd voor het team.",
        "This value will always remain at 1 (piece) or 1000 (grams)": "Deze waarde blijft altijd 1 (stuk) of 1000 (gram)",
        "This website uses cookies": "Deze website maakt gebruik van cookies",
        "This week": "Deze week",
        "Ticket image": "Ticket afbeelding",
        "Time": "Tijd",
        "Timeline order": "Tijdlijn volgorde",
        "Times used": "Aantal keren gebruikt",
        "Timeslot": "Tijdslot",
        "Timeslots": "Tijdsloten",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.": "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.",
        "To the webshop": "Naar de webshop",
        "today": "Vandaag",
        "Toggle navigation": "Schakel navigatie",
        "Token Name": "Token Naam",
        "tomorrow": "Morgen",
        "Too Many Requests": "Te veel serververzoeken",
        "Total": "Totaal",
        "Total excl. VAT": "Totaal excl. BTW",
        "Total excl. VAT\":  \"Totaal excl. BTW\",": "\"Totaal excl. BTW\":  \"Totaal excl. BTW\",",
        "Total incl. VAT": "Totaal incl. BTW",
        "trans fat": "Transvet",
        "Translated": "Vertaald",
        "Two Factor Authentication": "Twee-factor-authenticatie",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.": "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application.": "Twee-factor-authenticatie is nu ingeschakeld. Scan de volgende QR-code met de authenticatie applicatie op je telefoon.",
        "Type": "Soort",
        "Uithoorn: delivery costs": "Uithoorn: verzendkosten",
        "Uithoorn: Min price for delivery": "Uithoorn: Min prijs voor levering",
        "Unauthorized": "Onbevoegd",
        "Unavailable code": "Code niet beschikbaar",
        "Uncombinable": "Niet combineerbaar",
        "Unfortunately no products were found with this filter, please remove the filter and try again": "Helaas zijn er geen producten gevonden met dit filter. Verwijder het filter en probeer het opnieuw.",
        "Unfortunately, we cannot deliver the order to the address you specified. You can pick up the order at your desired time in our store!": "Helaas kunnen wij de bestelling niet bezorgen op het door u opgegeven adres. U kunt de bestelling op het door u gewenste tijdstip afhalen in onze winkel!",
        "Unit type": "Eenheid type",
        "Unit value": "Eenheid waarde",
        "Update Password": "Wachtwoord Aanpassen",
        "Update your account's profile information and email address.": "Pas je profiel informatie en e-mailadres aan.",
        "Use `-1` for unlimited usage": "Gebruik -1 voor oneindig gebruik",
        "Use a recovery code": "Gebruik een herstelcode",
        "Use an authentication code": "Gebruik een autorisatiecode",
        "User": "Gebruiker",
        "Users": "Gebruikers",
        "Value": "Waarde",
        "Variant": "Variant",
        "Variant filter": "Variant filter",
        "Variant label": "Variant label",
        "Variants": "Varianten",
        "Variants for dropdown": "Varianten voor dropdown",
        "VAT": "BTW",
        "Vat rate": "BTW tarief",
        "vegan": "Vegan",
        "Verify Email Address": "Verifieer e-mailadres",
        "Verify Your Email Address": "Verifieer je e-mailadres",
        "View": "Bekijk",
        "View all products": "Bekijk alle producten",
        "View category": "Bekijk categorie",
        "View product": "Bekijk product",
        "View your order here:": "Bekijk hier jouw bestelling:",
        "Visible": "Zichtbaar",
        "We have received your message and will contact you as soon as possible.": "We hebben je bericht ontvangen en zullen zo snel mogelijk contact met je opnemen.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We hebben uw bericht ontvangen. Een van onze medewerkers zal zo spoedig mogelijk contact met u opnemen.",
        "We have received your order and are now preparing  it for delivery, you can expect your order at:": "Wij hebben uw bestelling ontvangen en maken deze nu klaar voor levering, u kunt uw bestelling verwachten op:",
        "We have received your order and are now preparing it for delivery, you can expect your order at:": "Wij hebben uw bestelling ontvangen en maken deze nu klaar voor levering, u kunt uw bestelling verwachten op:",
        "We were unable to find a registered user with this email address.": "Er is geen gebruiker met dit mailadres.",
        "We've done our best, but it seems that this page no longer exists or may have moved. Of course you can always go to the": "We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet meer bestaat of verplaatst is. U kunt natuurlijk altijd naar de",
        "Website": "Website",
        "Website and cookies": "Website en cookies",
        "week": "Week",
        "Weekday": "Weekdag",
        "Weight": "Gewicht",
        "weight": "Gewicht",
        "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.": "Als twee-factor-authenticatie is ingeschakeld, wordt je tijdens de authenticatie om een veilige, willekeurige token gevraagd. Je kunt dit token ophalen uit de Google Authenticator-applicatie op je telefoon.",
        "Whoops!": "Oeps!",
        "Whoops! Something went wrong.": "Oeps! Er is iets misgelopen.",
        "Would you like to have your order delivered or picked up at our store?": "Wilt u uw bestelling laten bezorgen of afhalen in onze winkel?",
        "Year": "Jaar",
        "Yes": "Ja",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You have been invited to join the {team} team!": "Je bent uitgenodigd om lid te worden van team {team}!",
        "You have enabled two factor authentication.": "Je hebt twee-factor-authenticatie ingeschakeld.",
        "You have not enabled two factor authentication.": "Je hebt twee-factor-authenticatie niet ingeschakeld.",
        "You may accept this invitation by clicking the button below:": "Je kunt de uitnodiging accepteren door op de volgende knop te klikken:",
        "You may delete any of your existing tokens if they are no longer needed.": "Je kunt al je bestaande tokens verwijderen als ze niet langer nodig zijn.",
        "You may not delete your personal team.": "Je mag je persoonlijke team niet verwijderen.",
        "You may not leave a team that you created.": "Je kan het team dat je aangemaakt hebt niet verlaten.",
        "You'll hear from us as soon as possible": "Je zult zo spoeding mogelijk van ons horen",
        "You\\'ll hear from us as soon as possible": "Je hoort zo snel mogelijk van ons",
        "Your cart is empty": "Uw winkelwagen is leeg",
        "Your cart is empty.": "Uw winkelwagen is leeg.",
        "Zip*": "Postcode*",
        "Zoeken:": "Zoeken:",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "can": "{Attribute} bevat een waarde waar je niet bevoegd voor bent.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "contains": "{Attribute} mist een vereiste waarde.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "extensions": "{Attribute} moet een van de volgende bestandsextensies hebben: {values}.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "hex_color": "{Attribute} moet een geldige hexadecimale kleurcode zijn.",
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "list": "Het veld {attribute} moet een lijst zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "present_if": "{Attribute} moet aanwezig zijn als {other} {value} is.",
            "present_unless": "{Attribute} moet aanwezig zijn tenzij {other} {value} is.",
            "present_with": "{Attribute} moet aanwezig zijn als {values} aanwezig is.",
            "present_with_all": "{Attribute} moet aanwezig zijn als {values} aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_if_accepted": "The {attribute} field is prohibited when {other} is accepted.",
            "prohibited_if_declined": "The {attribute} field is prohibited when {other} is declined.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_if_declined": "{Attribute} is verplicht indien {other} is geweigerd.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "first_name": "Voornaam",
                "last_name": "Achternaam",
                "postal_code": "Postcode",
                "street_number": "Huisnummer",
                "street_name": "Straatnaam",
                "street_addition": "Toevoeging",
                "city": "Woonplaats",
                "same_address": "Factuuradres gelijk aan bezorgadres",
                "invoice_postal_code": "Factuurpostcode",
                "invoice_street_number": "Factuurhuisnummer",
                "invoice_street_name": "Factuurstraatnaam",
                "invoice_street_addition": "Factuurtoevoeging",
                "invoice_city": "Factuurwoonplaats",
                "phone_number": "Telefoonnummer",
                "delivery_method": "Bezorgmethode",
                "delivery_date": "Bezorgdatum",
                "interval_date": "Interval datum",
                "pickup_location": "Afhaallocatie",
                "terms": "Algemene voorwaarden",
                "newsletter": "Nieuwsbrief",
                "email": "E-mailadres"
            }
        },
        "routes": {
            "frequently-asked-questions": "veelgestelde-vragen",
            "about-us": "over-ons",
            "privacy-statement": "privacy-verklaring",
            "product": "product",
            "products": "producten",
            "category": "categorie",
            "categories": "categorieen",
            "terms-and-conditions": "algemene-voorwaarden",
            "website-and-cookies": "website-en-cookies",
            "supply-and-delivery": "levering-en-bezorging"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met dit e-mailadres."
        }
    }
}
