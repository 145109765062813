<template>
    <Listbox
        v-model="selected"
        :multiple="multiple"
        as="div"
    >
        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">
            {{ label }}
        </ListboxLabel>
        <div class="relative mt-2">
            <ListboxButton
                class="relative w-full cursor-default rounded-full bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6"
            >
                <span class="block truncate"><template v-if="Array.isArray(selected) && selected.length">{{
                                                 options
                                                     .filter((i) => selected.includes(i.id))
                                                     .map((a) => a.title)
                                                     .join(', ')
                                             }}</template>

                    <template v-else-if="!Array.isArray(selected) && selected">{{
                        options
                            .filter((i) => selected === i.id)
                            .map((a) => a.title)
                            .join(', ')
                    }}</template>
                    <template v-else>{{ $t('Select categorie') }}</template></span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        aria-hidden="true"
                        class="size-5 text-gray-400"
                    />
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        v-for="option in options"
                        :key="option.id"
                        v-slot="{ active, selected }"
                        :value="option.id"
                        as="template"
                    >
                        <li
                            :class="[
                                active ? 'bg-primary-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                        >
                            <span
                                :class="[
                                    modelValue.length && selected ? 'font-semibold' : 'font-normal',
                                    'block truncate',
                                ]"
                            >{{ option.title }}</span>

                            <span
                                v-if="modelValue.length && selected"
                                :class="[
                                    active ? 'text-white' : 'text-primary-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                ]"
                            >
                                <CheckIcon
                                    aria-hidden="true"
                                    class="size-5"
                                />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script setup>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { computed } from 'vue';

const props = defineProps({
    modelValue: Object,
    options: Object,
    label: String,
    multiple: Boolean,
});

const emit = defineEmits(['update:modelValue']);

const selected = computed({
    get() {
        if (!props.multiple) {
            return props.modelValue[0];
        }
        return props.modelValue;
    },
    set(value) {
        let input = value;
        if (props.modelValue == input) {
            input = [];
        }

        if (!Array.isArray(input)) {
            input = [input];
        }

        emit('update:modelValue', input);
    },
});
</script>
